<template>
    <li class="d-flex bid-item flex-wrap p-2">
        <h5 class="p-0 m-0">
            <money :currentBidProps="bid.money" :currency="bid.currency"></money>
        </h5>
        <h5 class="text-black px-2 py-0 m-0 text-break">{{ bid.nick }}</h5>
        <span class="text-muted">{{ time }}</span>
    </li>
</template>

<script>
import Money from './Money.vue';
export default {
    components: { 
            Money 
        },
    props: {
        bid: {
            required: true
        },
        language: {
            required: true
        }
    },
    created: function () {
        moment.locale(this.language)
        this.time = moment(this.bid.when).fromNow();
        window.setInterval(
            this.updateTime, 60000);
    },
    data() {
        return {
            time: null,

        }
    },
    methods: {
        updateTime() {
            moment.locale(this.language)
            this.time = moment(this.bid.when).fromNow();
        }
    },
};

</script>
