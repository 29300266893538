<template>
    <div id="ModalBid" aria-hidden="true" aria-labelledby="ModalBidTitle" class="modal fade" role="dialog"
         tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content border-0 bg-transparent">
                <div class="modal-body position-relative text-center p-0 pb-5">
                    <div class="text-right pr-1">
                        <button aria-label="Close" class="close float-none webkit" data-dismiss="modal" type="button">
                            <i aria-hidden="true" class="fas fa-times text-white"></i>
                        </button>
                    </div>
                    <form @submit.prevent>
                        <div class="input-group">
                            <input type="number" class="form-control" :style="{ 'box-shadow': '0 0 0 0 rgba(0, 0, 0)' }" v-model="price">
                            <span class="input-group-text" :style="{ 'border-radius': '0 0.25rem 0.25rem 0' }">{{this.currency}}</span>
                        </div>
                        <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center">
                            <div class="pt-3 mr-4 mr-sm-0 pr-2 pr-sm-3">
                                <button :title="__('auction.max_bid_info')" class="btn p-0 mr-2 button-fw"
                                        data-placement="bottom" data-toggle="tooltip">
                                    <i class="far fa-question-circle fa-lg text-white"></i>
                                </button>
                                <button class="btn py-1 px-4 bg-orange button-fw text-white border-0" type="submit"
                                        v-on:click="placeMaxBid">
                                    <h6 class="text-uppercase font-weight-bold p-0 m-0">{{__('auction.place_max_bid') }}</h6>
                                </button>
                            </div>
                            <div class="pt-3">
                                <button class="btn py-1 px-4 text-white bg-green button-fw border-0" type="submit"
                                        v-on:click="placeBid">
                                    <h6 class="text-uppercase font-weight-bold p-0 m-0">{{__('auction.place_my_bid') }}</h6>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        route: {
            type: String,
            required: true
        },
        maxBidRoute: {
            type: String,
            required: true
        },
        nick: {
            required: true
        },
        userRoute: {
            required: false
        },
        currency: {
            required: true
        }
    },
    data() {
        return {
            price: null,
            config: {
                spinner: false,
                suffix: " " + this.currency ,
                precision: 0,
                decimal: '.',
                thousands: ',',
                masked: false,
                disableNegative: true,
                align: "center",
            }
        }
    },
    methods: {
        placeBid() {
            if (this.price.length >= 10) {
                Vue.$toast.open({
                    message: this.__('auction.toast.bid_to_long'),
                    type: 'error',
                });
            } else {
                axios.post(this.route, {
                    price: this.price
                })
                    .then(response => {
                        this.$store.dispatch('changePrice', this.price);
                        this.$store.dispatch('changeTime', response.data.new_time);
                        this.$store.dispatch('changeWinner', this.nick);
                        this.$store.dispatch('changeWinnerRoute', this.userRoute);
                        this.price = 0;
                        Vue.$toast.open({
                            message: this.__('auction.bid_success'),
                            type: 'success',
                        });
                    })
                    .catch(error => {
                        let err = error.response.data
                        if (error.response.data[0] == "'") {
                            err = JSON.parse(error.response.data.substring(1));
                        }

                        Vue.$toast.open({
                            message: err.errors.price[0],
                            type: 'error',
                        });
                    });
            }
        },
        placeMaxBid() {
            axios.post(this.maxBidRoute, {
                price: this.price
            })
                .then(response => {
                    this.price = 0;
                    Vue.$toast.open({
                        message: this.__('auction.bid_success'),
                        type: 'success',
                    });
                })
                .catch(error => {
                    let err = error.response.data
                    if(error.response.data[0] == "'") {
                        err = JSON.parse(error.response.data.substring(1));
                    }
                    Vue.$toast.open({
                        message:  err.errors.price[0],
                        type: 'error',
                    });
                });
        },
    },
}

</script>

