<template>
    <i v-if="!isLogged" class="far fa-star fa-lg pb-1 text-white favourite-icon" data-toggle="modal" data-target="#ModalLogin"></i>
    <i v-else-if="isLogged && !fav" class="far fa-star fa-lg pb-1 text-white favourite-icon" v-on:click="addFav"></i>
    <i v-else-if="isLogged && fav" class="fas fa-star fa-lg pb-1 text-orange" v-on:click="deleteFav"></i>
</template>

<script>
    export default {
        props: {
            auctionFavRoute: {
                required: true
            },
        },
        methods: {
            addFav() {
                axios.post(this.auctionFavRoute).then(res => {
                    this.$store.dispatch('changeFav', true);
                });
            },
            deleteFav() {
                axios.post(this.auctionFavRoute, {
                    _method: 'DELETE'
                }).then(res => {
                    this.$store.dispatch('changeFav', false);
                });
            }
        },
        computed: {
            isLogged: function () {
                return this.$store.state.isLogged;
            },
            fav: function() {
                return this.$store.state.fav;
            }
        }
    };

</script>
