<template>
    <div class="container py-5">
        <div class="listings">
            <auction v-for="auction in auctions" :key="auction.id" :auction="auction"
                :is-logged="isLogged"></auction>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            auctionsRoute: {
                required: true
            }
        },
        created: function () {
            this.getAuctions();
        },
        data() {
            return {
                auctions: {},
                currentRoute: null,
                links: null,
                time: Date.now(),
                old: false,
                total: 0,
                perPage: 24,
                noResult: 'images/no_results.png',
                searched: false,
            };
        },
        methods: {
            getAuctions() {
                this.Url.searchParams.set('time', Date.now());
                this.Url.searchParams.set('per_page', 4);
                axios.get(this.Url.href).then(res => {
                    if (res.data.time >= this.time) {
                        this.auctions = res.data.auctions.data;
                        this.time = res.data.time;
                    }
                });
            },
        },
        computed: {
            Url: function () {
                return new URL(this.auctionsRoute);
            },
            isLogged: function () {
                return this.$store.state.isLogged;
            }
        }
    };

</script>
