<template>

    <div :class="comment.seller ? borderOrange : bgTransparent" class="d-flex mb-3 p-2 rounded" :id="`question-${comment.id}`">
        <div>
            <img alt="photo" :src="comment.avatar" class="rounded-circle img-cover" width="50px" height="50px">
        </div>
        <div class="w-100">
            <div class="d-flex justify-content-between pl-3">
                <div class="d-flex flex-column flex-sm-row">
                    <div>
                        <a :href="comment.userRoute" :class="comment.seller ? textOrange : textDark" class="font-weight-bolder text-break">{{ comment.nick }}</a>
                        <span v-if="comment.seller"
                            class="text-black ml-1 rounded user-category text-uppercase font-weight-bolder">
                            {{ __('auction.seller') }}
                        </span>
                    </div>
                    <div class="ml-sm-1">
                        <span class="text-muted">{{ time }}</span>
                    </div>
                </div>
            </div>
            <div class=" pl-3 d-flex justify-content-between">
                <span v-if="comment.isBid">
                    <button class="btn bg-orange pb-1 pt-0 text-white button-fw user-category mx-2 cursor-default">{{ __('auction.bid') }} 
                        <money :currentBidProps="comment.money" :currency="comment.currency"></money>
                    </button>
                </span>
                <div v-else>
                    <span class="text-break">
                        <span v-if="comment.reply" class="text-muted" v-on:click="goTo(`question-${comment.id}`)">
                            RE: {{comment.reply.user.name}}({{comment.reply.comment.content}}):
                        </span>
                        {{ comment.content }}
                    </span>
                </div>
            </div>
            <div v-if="!isReply" class="d-flex pl-3">
                <like v-if="!comment.isBid" :is-like-props='comment.isLike' :like-route='comment.likeRoute'
                    :likes-props='comment.likes' @likes-changed='likesChanged' />
                <a v-if="!comment.isBid && (isAdmin || isSeller)" v-on:click="reply" class="btn text-dark text-center button-fw ml-1 p-0" type="button" data-toggle="tooltip" :title="__('auction.reply_to_comment')">
                    <i class="fas fa-share text-success"></i>
                </a>
                <a v-if="comment.canModify || isAdmin && !comment.isBid" v-on:click="deleteComment"
                    class="btn text-dark text-center p-0 mx-1" type="button">
                    <i class="far fa-trash-alt text-danger"></i>
                </a>
                <report v-if="!comment.isBid && (isLogged || isSeller)" :is-report-props='comment.isReport' :report-route='comment.reportRoute'/>
            </div>
        </div>
        <delete-modal v-if="isDelete" :route="comment.deleteRoute" :type="typeTranslation" @closed="isDelete = false" @deleted="commentDeleted"></delete-modal>
    </div>
</template>

<script>
    import Like from './Like.vue';
    import Report from './Report.vue';
    import Money from './Money.vue';
    import DeleteModal from './DeleteModal.vue';
    export default {
        components: {
            Report,
            Like,
            Money,
            DeleteModal
        },
        props: {
            comment: {
                required: true
            },
            isAdmin: {
                required: true
            },
            isReply: {
                required: false
            },
            isSeller: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                isDelete: false,
                bgTransparent: 'bg-transparent',
                borderOrange: 'border-orange',
                textOrange: 'text-orange',
                textDark: 'text-dark',
                typeTranslation: this.__('auction.comment')
            }
        },
        methods: {
            deleteComment() {
                this.isDelete = true;
            },
            commentDeleted(data) {
                this.$emit('comment-deleted', data);
                Vue.$toast.open({
                        message: this.__('auction.toast.comment_delete_successful'),
                        type: 'warning',
                    });
            },
            likesChanged(like) {
                this.comment.likes += like;
            },
            reply() {
                this.$emit('comment-reply', this.comment);
            },
            goTo(id) {
                if(document.getElementById(id))
                    document.getElementById(id).scrollIntoView();
                else
                    this.$emit('scroll-to', id, this.comment.isQuestion);
            }
        },
        computed: {
            time: function () {
                return new Date(this.comment.when).toLocaleString('en-US');
            },
            isLogged: function () {
                return this.$store.state.isLogged;
            }
        }
    }

</script>
