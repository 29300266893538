<template>
  <div class="d-flex align-items-center position-relative px-1" style="user-select: none;">
    <i
      v-if="!isLogged"
      class="far fa-star mr-3 favourite-icon fa-lg text-white addFav"
      data-toggle="modal"
      data-target="#ModalLogin"
    />
    <i
      v-else-if="isLogged && !fav"
      class="far fa-star mr-3 favourite-icon fa-lg text-white addFav"
      v-on:click="addFav"
    />
    <i
      v-else-if="isLogged && fav"
      class="fas fa-star mr-3 fa-lg text-orange deleteFav"
      v-on:click="deleteFav"
    />
    <a
      href="#"
      class="share-auction mr-2"
      :data-href="shareRoute"
      data-toggle="modal"
      data-target="#ShareModal"
    >
      <i class="fas fa-share-alt fa-lg text-white" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    favProps: {
      required: true,
    },
    auctionFavRoute: {
      required: true,
    },
    shareRoute: {
      type: String,
      required: true,
    },
    status: [String, null],
  },
  created: function () {
    this.fav = this.favProps;
  },
  data() {
    return {
      fav: null,
    };
  },
  methods: {
    addFav() {
      axios.post(this.auctionFavRoute).then((res) => {
        this.fav = true;
        Vue.$toast.open({
          message: this.__("auction.added_to_favorites"),
          type: "success",
        });
      });
    },
    deleteFav() {
      axios
        .post(this.auctionFavRoute, {
          _method: "DELETE",
        })
        .then((res) => {
          this.fav = false;
          Vue.$toast.open({
            message: this.__("auction.removed_to_favorites"),
            type: "warning",
          });
        });
    },
  },
  watch: {
    favProps: {
      handler(value) {
        this.fav = this.favProps;
      },
    },
  },
  computed: {
    isLogged: function () {
      return this.$store.state.isLogged;
    },
    title() {
      return this.__("auction." + (this.status === "S" ? "run" : "run_and_drive"));
    },
  },
};
</script>
