<template>
    <div class="card-body p-0">
          <table class="table pt-3">
            <my-bid v-for="auction in auctions" :key="auction.id" :auction="auction" :status="status"></my-bid>
            <tr v-if="total == 0">
                <td class="border-0 px-3 py-0 text-center text-md-left">
                    <span>{{ __('auction.no_results') }}</span>
                </td>
            </tr>
        </table>
        <nav class="d-flex justify-content-center" v-if="total > per_page">
            <ul class="pagination">
                <li v-for="link in links" :key="link.label" class="page-item"
                    :class="[link.active && 'active', currentPage === 1 && link.label === 'Prev' && 'disable', currentPage === Math.ceil(total / per_page) && link.label === 'Next' && 'disable' ]">
                    <a v-if="currentPage == link.label || link.url == null" class="btn page-link">
                        <i v-if="link.label === 'Prev'" class="fas fa-chevron-left fa-sm pr-1"></i>
                        {{link.label}}
                        <i v-if="link.label === 'Next'" class="fas fa-chevron-right fa-sm pl-1"></i>

                    </a>
                    <a v-else class="btn page-link" v-on:click="changePage(link.url)">
                        <i v-if="link.label === 'Prev'" class="fas fa-chevron-left fa-sm pr-1"></i>
                        {{link.label}}
                        <i v-if="link.label === 'Next'" class="fas fa-chevron-right fa-sm pl-1"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    import MyBid from './MyBid.vue';
    export default {
        components: {
            MyBid
        },
        props: {
            auctionsRoute: {
                required: true
            },
            status: {
                required: true
            }
        },
        data() {
            return {
                auctions: {},
                currentRoute: null,
                links: null,
                currentPage: null,
                total: null,
                per_page: null
            };
        },
        created: function () {
            if (new URL(window.location.href).searchParams.get("my_bids" + this.status + "_page")) {
                let tmp = new URL(this.auctionsRoute)
                tmp.searchParams.set("page", new URL(window.location.href).searchParams.get("my_bids" + this.status + "_page"));
                this.currentRoute = tmp.href;
            } else
                this.currentRoute = this.auctionsRoute;
        },
        methods: {
            changePage(url) {
                let tmp = new URL(url);
                if (new URL(this.auctionsRoute).searchParams.get('status'))
                    tmp.searchParams.set('status', new URL(this.auctionsRoute).searchParams.get('status'));
                this.currentRoute = tmp.href;
                this.Url.searchParams.set("my_bids" + this.status + "_page", new URL(url).searchParams.get("page"));
                window.history.pushState(null, null, this.Url);
            },
        },
        watch: {
            currentRoute: function () {
                axios.get(this.currentRoute).then(res => {
                    this.auctions = res.data.auctions.data;
                    this.links = res.data.auctions.links;
                    this.currentPage = res.data.auctions.current_page;
                    this.per_page = res.data.auctions.per_page;
                    this.total = res.data.auctions.total;
                });
            }
        },
        computed: {
            Url: function () {
                return new URL(window.location.href);
            }
        }
    };

</script>
