<template>
  <div v-if="timeLeft && timeLeft < twoMinutesMiliseconds" class="time-bar-wrapper position-relative">
    <div class="time-bar rounded bg-red" :style="widthBar">
    </div>
  </div>
</template>

<script>
export default {
    props: {
        auctionEndProps: {
            required: true
        },
    },
    data() {
        return {
            timeLeft: null,
            timerId: null,
            twoMinutesMiliseconds: 60000,
        }
    },
    methods: {
        updateTime() {
            let newTime = this.end - Date.now();

            if (newTime > 0) {
                this.timeLeft = newTime;
            } else {
                clearInterval(this.timerId)
            }
        },
    },
    computed: {
        end() {
            return (new Date(this.auctionEndProps)).getTime();
        },
        widthBar() {
        const percent = (this.timeLeft / this.twoMinutesMiliseconds) * 100;
            return `width: ${percent}%;`;
        },
    },
    watch: {
        end: {
            immediate: true,
            handler(value) {
                if(this.timerId)
                    clearInterval(this.timerId)
                this.updateTime();
                this.timerId = window.setInterval(
                this.updateTime, 1000);
            }
        }
    },
}
</script>
