<template>
    <a v-on:click="show" class="btn rounded-0 text-uppercase mr-n2">
        <i class="fas fa-times fa-lg"></i>
    </a>
</template>

<script>
export default {
    methods: {
        show() {
            this.$store.dispatch('changeMobileSearch', !this.display);
        }
    },
    computed: {
        display: function () {
            return this.$store.state.mobileSearch;
        },
    }
};
</script>