<template>
    <div class="position-relative">
        <div id="comments" class="section-anchor" ></div>
        <div class="px-2 px-lg-0 mb-4">
            <div class="card border-0 py-4 px-md-4">
                <div class="pb-5">
                    <h5 class="font-weight-bolder pb-3">{{ __('auction.bids_and_questions') }}</h5>
                    <comment
                        v-if="reply"
                        :comment="reply"
                        :isAdmin="false"
                        :isReply="true"
                        :is-seller="isSeller"
                    ></comment>
                    <add-comment v-if="isLogged" :focus="focus" :comment-route="commentRoute" :question-route="questionRoute" :reply-route="replyRoute" :reply="reply" :is-question="isQuestion" @comment-added="addComment" @question-added="addQuestion" @reply-clear="clearReply"  :is-admin="isAdmin" :is-seller="isSeller"></add-comment>
                    <div v-else class="d-flex pt-3">
                        <button class="btn border-0 bg-black py-1 px-3 text-white button-fw" type="button"
                            data-toggle="modal" data-target="#ModalLogin">
                            {{ __('auction.sign_in_to_ask') }}
                        </button>
                    </div>
                </div>
                <h5 class="font-weight-bolder pb-3">{{ __('auction.questions_and_anserws') }}</h5>

                <div id="question">
                    <div v-if="questions.length">
                        <div v-for="comment in questions" :key="comment.id">
                            <comment :comment="comment" :is-admin="isAdmin" @scroll-to="scrollTo" @comment-deleted="questionDeleted" @comment-reply="commentReply" :is-seller="isSeller"></comment>
                        </div>
                        <div v-if="nextPageQuestions" class="py-4">
                            <button v-on:click="loadMoreQuestions" class="btn bg-black text-white button-fw">
                                {{ __('auction.load_more') }}
                            </button>
                        </div>
                    </div>
                    <div v-else class="pb-3">
                        <span>{{ __('auction.no_questions_yet') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import AddComment from './AddComment.vue';
    import Comment from './Comment.vue';
    export default {
        components: {
            AddComment,
            Comment
        },
        props: {
            commentRoute: {
                required: true
            },
            questionRoute: {
                required: true
            },
            replyRoute: {
                required: true
            },
            commentsRoute: {
                type: String,
                required: true
            },
            questionsRoute: {
                type: String,
                required: true
            },
            channel: {
                type: String,
                required: true
            },
            isAdmin: {
                required: true
            },
            isSeller: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                comments: {},
                questions: {},
                nextPageComments: null,
                nextPageQuestions: null,
                avatar: '/images/avatar.png',
                reply: null,
                isQuestion: null,
                focus: null
            };
        },
        methods: {
            addComment(data) {
                this.comments.unshift(data);
                this.reply = null;
            },
            addQuestion(data) {
                this.questions.unshift(data);
                this.reply = null;
            },
            getComments() {
                axios.get(this.commentsRoute).then(res => {
                    this.comments = res.data.comments.data;
                    this.nextPageComments = res.data.comments.next_page_url;
                });
            },
            getQuestions() {
                axios.get(this.questionsRoute).then(res => {
                    this.questions = res.data.comments.data;
                    this.nextPageQuestions = res.data.comments.next_page_url;
                });
            },
            async loadMoreComments() {
                await axios.get(this.nextPageComments).then(res => {
                    let keys = Object.keys(res.data.comments.data);
                    for(let i = 0; i < keys.length; i++) {
                        this.comments.push(res.data.comments.data[keys[i]]);
                    }
                    this.nextPageComments = res.data.comments.next_page_url;
                });
            },
            async loadMoreQuestions() {
                await axios.get(this.nextPageQuestions).then(res => {
                    let keys = Object.keys(res.data.comments.data);
                    for(let i = 0; i < keys.length; i++) {
                        this.questions.push(res.data.comments.data[keys[i]]);
                    }
                    this.nextPageQuestions = res.data.comments.next_page_url;
                });
            },
            questionDeleted(data) {
                this.questionDelete(data.id);
            },
            questionDelete(id){
                this.clearReplyId(id);
                this.questions = this.questions.filter(function (ele) {
                    return ele.id != id;
                });
                var that = this;
                this.questions.forEach(function (ele) {
                    if(ele.reply && ele.reply.comment.id == id) {
                        that.questionDelete(ele.id);
                    }
                });
            },
            commentDeleted(data) {
                this.commentDelete(data.id);
            },
            commentDelete(id){
                this.clearReplyId(id);
                this.comments = this.comments.filter(function (ele) {
                    return ele.id != id;
                });
                var that = this;
                this.comments.forEach(function (ele) {
                    if(ele.reply && ele.reply.comment.id == id) {
                        that.commentDelete(ele.id);
                    }
                });
            },
            commentReply(data) {
                this.reply = data;
                this.isQuestion = data.isQuestion;
                document.getElementById('comments').scrollIntoView({ behavior: 'smooth', block: 'start' });
            },
            listenNewComments() {
                Echo.channel(this.channel)
                    .listen('CommentAdded', (data) => {
                        this.comments.unshift(data.data);
                    })
            },
            listenNewQuestions() {
                Echo.channel(this.channel)
                    .listen('QuestionAdded', (data) => {
                        this.questions.unshift(data.data);
                    })
            },
            listenLikes() {
                Echo.channel(this.channel)
                    .listen('LikesChanged', (data) => {
                        if(data.data.isQuestion)
                            this.questions.forEach(element => {
                                if (element.id == data.data.id) {
                                    element.likes = data.data.amount;
                                    return;
                                }
                            });
                        else
                            this.comments.forEach(element => {
                                if (element.id == data.data.id) {
                                    element.likes = data.data.amount;
                                    return;
                                }
                            });
                    })
            },
            listenNewBids() {
                Echo.channel(this.channel)
                    .listen('BidCommentAdded', (data) => {
                        this.comments.unshift(data.data);
                    })
            },
            listenDeletedComments() {
                Echo.channel(this.channel)
                    .listen('CommentDeleted', (data) => {
                        if(data.data.isQuestion)
                            this.questions = this.questions.filter(function (ele) {
                                return ele.id != data.data.id;
                            });
                        else
                            this.comments = this.comments.filter(function (ele) {
                                return ele.isBid || ele.id != data.data.id;
                            });
                    })
            },
            clearReply() {
                this.reply = null;
                this.isQuestion = null;
            },
            clearReplyId(id) {
                if(this.reply && this.reply.id == id)
                    this.clearReply();
            },
            async loadMore(isQuestion) {
                isQuestion? await this.loadMoreQuestions(): await this.loadMoreComments();
            },
            async scrollTo(id, isQuestion){
                await this.loadMore(isQuestion)
                if(document.getElementById(id))
                    document.getElementById(id).scrollIntoView();
                else
                    this.scrollTo(id, isQuestion);
            }
        },
        mounted() {
            this.getComments();
            this.getQuestions();
            this.listenNewComments();
            this.listenNewQuestions();
            this.listenNewBids();
            this.listenDeletedComments();
            this.listenLikes();
        },
        computed: {
            isLogged: function () {
                return this.$store.state.isLogged;
            }
        }
    };

</script>
