<template>
<transition name="modal">
    <div tabindex="-1" role="dialog" class="modal fade show" style="padding-right: 17px; display: block;">
        <div role="document" class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content border-0">
                <div class="modal-header border-0">
                    <button type="button" class="close webkit" v-on:click="close">
                        <i class="fas fa-times text-muted"></i>
                    </button>
                </div>
                <div class="modal-body position-relative text-center p-0 pb-5">
                    <div class="bg-transparent border-0 text-center pb-3">
                        <h2 class="title font-weight-bolder">
                            {{ __('auction.report_title') }}
                        </h2>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="d-flex flex-column align-items-start col-10 col-md-6 p-0 m-0 pb-3">
                            <span class="text-muted pb-2">{{ __('auction.reason') }}:</span>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                    value="Abusive content" v-model="picked">
                                <label class="form-check-label text-muted" for="exampleRadios1">
                                    {{ __('auction.abusive_content') }}
                                </label>
                            </div>
                            <div class="form-check py-2">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                    value="Spam or wrong content" v-model="picked">
                                <label class="form-check-label text-muted" for="exampleRadios2">
                                    {{ __('auction.spam_or_wrong_content') }}
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3"
                                    value="False information or scam" v-model="picked">
                                <label class="form-check-label text-muted" for="exampleRadios3">
                                    {{ __('auction.false_information_or_scam') }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                        <span class="text-muted pb-1">{{ __('auction.your_reason') }}:</span>
                        <textarea class="border rounded col-10 col-md-6 w-100" v-model="explanation"></textarea>
                    </div>
                    <div class="form-group mb-0 pt-3">
                        <div class="d-flex flex-column align-items-center">
                            <button type="submit" v-on:click="report"
                                class="col-lg-3 col-6 btn text-white bg-orange py-1 border-0 button-fw text-uppercase">
                                {{ __('auction.submit') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    props: {
        route: {
        type: String,
        required: true,
        },
    },
    data() {
        return {
            picked: null,
            explanation: ''
        }
    },
    methods: {
        report() {
            axios.post(this.route, {reason: this.picked? this.picked: this.explanation}
            ).then(res => {
                this.$emit('reported', true);
                this.close();
            })
            .catch (error => {
                Vue.$toast.open({
                    message: error.response.data.errors.reason[0],
                    type: 'error',
                });  
            });
        },
        show() {
            document.body.classList.add('modal-open');
            document.body.style.paddingRight = "17px";
            var elemDiv = document.createElement('div');
            elemDiv.classList.add('modal-backdrop');
            elemDiv.classList.add('fade');
            elemDiv.classList.add('show');
            document.body.appendChild(elemDiv);
        },
        close() {
            this.$emit('closed');
            document.body.classList.remove('modal-open');
            document.body.style.paddingRight = "0px";
            const elements = document.getElementsByClassName('modal-backdrop');
            while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
            }
        },
    },
    mounted() {
        this.show();
    },
};
</script>
