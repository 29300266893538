<template>
  <div class="app"> 
      <CarouselMain>
          <carousel-slide v-for="(value) in opinions" :key="value.id" class="carousel-slider">
              <img loading="lazy" :src="value.car" :alt="value.carName" class="imageStyling">
              <div class="carouselSlideBody">
                  <Rating class="mt-1" v-model="value.stars" :readonly="true" :cancel="false" />
                  <span class="carouselTextHead">{{ value.buyer }}</span>
                  <hr class="line">
                  <span class="carouselTextSub">{{ value.description }}</span>
              </div>
          </carousel-slide>
      </CarouselMain>
</div></template>

<script>
import CarouselMain from './CarouselMain.vue';
import CarouselSlide from './CarouselSlide';

export default {
  data() {
      return {
          opinions: [
              {
                  carName: 'BMW M3',
                  buyer: 'Stanisław Lech',
                  description: 'Samochód był w idealnym stanie. Podczas jazdy czułem się komfortowo. ',
                  car: "/images/opinions/bmw_m3.jpeg",
                  stars: 5,
                  id: 0,
              },
              {
                  carName: 'PORSCHE 911',
                  buyer: 'Bartłomiej Zawodny',
                  description: 'Samochód jest naprawdę szybki i wygodny.',
                  car: "/images/opinions/porsche_911.jpg",
                  stars: 5,
                  id: 1,
              },
              {
                  carName: 'MERCEDES-BENZ CLA 45 AMG',
                  buyer: 'Oskar Rogacki',
                  description: 'Jest szybki, wygodny i przyjemny w prowadzeniu.',
                  car: "/images/opinions/MERCEDES_BENZ_CLA_45_AMG.jpg",
                  stars: 5,
                  id: 2,
              },
              {
                  carName: 'JAGUAR XJ8 4.2 S',
                  buyer: 'Dawid Mondruk',
                  description: 'Byłem bardzo szczęśliwy, gdy znalazłem go na Bidpark.',
                  car: "/images/opinions/JAGUAR_XJ8_4.2_S.jpg",
                  stars: 5,
                  id: 3,
              },
              {
                  carName: 'BENTLEY CONTINENTAL GT W12',
                  buyer: 'Marcin Gądrek',
                  description: 'Silnik jest bardzo mocny, a doświadczenia z jazdy są świetne.',
                  car: "/images/opinions/BENTLEY_CONTINENTAL_GT_W12.jpg",
                  stars: 5,
                  id: 4,
              },
              {
                  carName: 'FERRARI 360 MODENA F1',
                  buyer: 'Filip Drzyga',
                  description: 'Zdecydowanie poleciłbym Bidpark każdemu.',
                  car: "/images/opinions/FERRARI_360_MODENA_F1.jpg",
                  stars: 5,
                  id: 5,
              },
          ],
      }
  },

  components: {
      CarouselMain: CarouselMain,
      CarouselSlide: CarouselSlide,
  }
}
</script>

<style scoped>
.line{
  border: 1px solid white;
}
.carouselTextHead{
  font-size: 20px;
  color:white; 
  font-weight: 900;
}
.carouselTextSub{
  font-size: 15px;
  color:white;
}
.imageStyling{
  width: 100%; 
  height: 50% !important; 
}
.carouselSlideBody{
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px; 
  padding-right: 30px;
}
.app {
  display: flex;
  justify-content: center;width: 300px; 
  height: 400px; 
  border-radius: 30px; 
  border: 1px;
}
.carousel {
  position: relative;
  overflow: hidden;
  width: 800px;
  height: 400px;
  z-index: 10;
}

.btn {
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  margin: 5px 10px;
  color: #FFF;
  height: 50px;
  width: 50px;
  position: absolute;
  margin-top: -25px;
  z-index: 2;
}

.btn:hover {
  cursor: pointer;
}

.btn:focus {
  outline: none;
}

.btn-next {
  top: 50%;
  right: 0;
}

.btn-prev {
  top: 50%;
  left: 0;
}

.carousel-slider {
  position: absolute;
  background: rgba(49, 138, 232, .7); 
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.carousel-slider img {
  width: 100%;
  height: 100%;
}
</style>