<template>

    <div class="container pb-5">
        <!-- car type -->
        <div class="d-flex justify-content-center">
            <ul class="nav nav-pills categories-pills d-flex justify-content-center col-12 pt-3 px-3 m-0" id="pills-tab" role="tablist">
                <li class="nav-item">
                    <div class="nav-link px-1 px-lg-4 py-1 tabs text-uppercase mr-2"
                        :class="params.type == 'PREMIUM' && 'active'"
                        @click="getAuctionsSearch('PREMIUM')">
                        {{ __('auction.filters.premium') }}
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link px-1 px-lg-4 py-1 tabs text-uppercase"
                        :class="params.type == null && 'active'" @click="getAuctionsSearch(null)">{{ __('auction.filters.all') }}
                    </div>
                </li>
            </ul>
        </div>
        <div class="d-flex pb-4">
            <ul class="nav nav-pills d-flex w-100 justify-content-between justify-content-lg-end categories-subpills pt-2 pt-md-0">
                <li class="nav-item pr-2 pl-lg-0 pl-2">
                    <div class="nav-link px-4 py-1 tabs "
                        :class="params.sort_by == 'end' && 'active'" @click="getAuctionsSortBy('end')">
                        {{ __('auction.filters.ending_soon') }}
                    </div>
                </li>
                <li class="nav-item pr-2 pr-lg-0">
                    <div class="nav-link px-4 py-1 mx-0 tabs"
                        :class="params.sort_by == 'created_at' && 'active'"
                        @click="getAuctionsSortBy('created_at')">
                        {{ __('auction.filters.latest') }}
                    </div>
                </li>
            </ul>
        </div>

        <!-- car listings -->
        <div v-if="searched && isLogged" class="px-3 px-lg-0 text-center text-lg-left">
            <a v-on:click="saveSearch" :class="total === 0 ? 'd-none' : '' " class="btn p-0 border-0 letter-0 text-dark mb-4 text-white font-weight-bolder">
                {{ __('auction.filters.save_search') }}
            </a>
        </div>
        <div v-show="total > 0">
            <div class="listings">
                <auction v-for="auction in auctions" :key="auction.id" :auction="auction"></auction>
            </div>
        </div>
        <div class="pt-4" v-show="total == 0">
            <div class="d-flex justify-content-center text-center">
                <h2 class="text-dark p-0 m-0 pr-3">{{ __('auction.no_cars') }}</h2>
            </div>
            <div v-if="searched && !isLogged" class="d-flex flex-column align-items-center text-center pt-3 px-2 px-md-0">
                <h5 class="text-dark p-0 m-0">{{ __('auction.filters.sign_in_to_save_search') }}</h5>
                <a class="btn sign-button bg-black text-white text-uppercase border-0 px-3 mt-3 font-weight-bolder" data-toggle="modal" data-target="#ModalLogin">{{ __('auction.filters.save_search') }}</a>
            </div>
            <div v-if="searched && isLogged" class="d-flex flex-column align-items-center text-center pt-3 px-2 px-md-0">
                <h5 class="text-dark p-0 m-0">{{ __('auction.filters.save_search_results') }}</h5>
                <a v-on:click="saveSearch" class="btn bg-black mt-3 border-0 letter-0 py-1 text-white font-weight-bolder text-uppercase">{{ __('auction.filters.save_search') }}</a>
            </div>
        </div>
        <nav class="d-flex justify-content-center" v-if="total > perPage">
            <ul class="pagination">
                <li v-for="link in links" :key="link.label" class="page-item" :class="[link.active && 'active', page === 1 && link.label === 'Prev' && 'disable', page === Math.ceil(total / perPage) && link.label === 'Next' && 'disable' ]">
                    <a v-if="page == link.label || link.url == null" class="btn page-link">
                        <i v-if="link.label === 'Prev'" class="fas fa-chevron-left fa-sm pr-1"></i>
                        {{link.label}}
                        <i v-if="link.label === 'Next'" class="fas fa-chevron-right fa-sm pl-1"></i>

                    </a>
                    <a v-else class="btn page-link"
                        v-on:click="changePage(link.url)">
                        <i v-if="link.label === 'Prev'" class="fas fa-chevron-left fa-sm pr-1"></i>
                        {{link.label}}
                        <i v-if="link.label === 'Next'" class="fas fa-chevron-right fa-sm pl-1"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        props: {
            auctionsRoute: {
                required: true
            },
            searchSaveRoute: {
                required: true
            },
        },
        created: function () {
            this.Url.searchParams.set('search_query', this.search);
            this.Url.searchParams.set('page', new URL(window.location.href).searchParams.get('page'));
            this.setParams();
            this.getAuctions();
        },
        data() {
            return {
                auctions: {},
                currentRoute: null,
                links: null,
                time: Date.now(),
                old: false,
                total: null,
                perPage: 24,
                noResult: 'images/no_results.png',
                searched: false,
            };
        },
        methods: {
            saveSearch() {
                let allParams = this.params;
                allParams.search_query = this.search;
                axios.post(this.searchSaveRoute, allParams)
                    .then(response => {
                        Vue.$toast.open({
                            message: this.__('auction.filters.search_saved'),
                            type: 'success',
                        });
                    })
                    .catch(function (error) {
                        if (error.response.data.errors.search_query)
                            Vue.$toast.open({
                                message: error.response.data.errors.search_query[0],
                                type: 'error',
                            });
                    });
            },
            getAuctions() {
                this.Url.searchParams.set('time', Date.now());
                this.Url.searchParams.set('page', this.page);
                this.Url.searchParams.set('per_page', 24);
                axios.get(this.Url.href).then(res => {
                    if (res.data.time >= this.time) {
                        this.auctions = res.data.auctions.data;
                        this.time = res.data.time;
                        this.links = res.data.auctions.links;
                        this.total = res.data.auctions.total;
                        this.perPage = res.data.auctions.per_page;
                        this.$store.dispatch('changePage', res.data.auctions.current_page)
                    }
                    this.searched = false;
                    for (const [key, value] of Object.entries(this.params)) {
                        if (value != null && key != 'sort_by')
                            this.searched = true;
                    }
                    if (this.search)
                        this.searched = true;
                    this.$store.dispatch('changeLoadingStatus', false);
                });
            },
            getAuctionsSearch(type) {
                this.$store.dispatch('changePage', '1')
                this.$store.dispatch('changeParams', {
                    paramName: 'type',
                    param: type
                });
            },
            getAuctionsSortBy(sort) {
                this.$store.dispatch('changePage', '1')
                this.$store.dispatch('changeParams', {
                    paramName: 'sort_by',
                    param: sort
                });
            },
            setParams() {
                let values = Object.values(this.params);
                let keys = Object.keys(this.params);
                for (let i = 0; i < keys.length; i++)
                    if (values[i] != null)
                        this.Url.searchParams.set(keys[i], values[i]);
                    else
                        this.Url.searchParams.set(keys[i], '');
                if (!this.params.status)
                    this.Url.searchParams.set('status', 'ongoing');
            },
            changePage(url) {
                this.$store.dispatch('changeLoadingStatus', true);
                this.$store.dispatch('changePage', new URL(url).searchParams.get('page'));
                let newUrl = new URL(window.location.href);
                newUrl.searchParams.set('page', new URL(url).searchParams.get('page'));
                window.history.pushState(null, null, newUrl);
                this.getAuctions();
                $("html, body").animate({ scrollTop: 0 }, "fast");
            },
        },
        watch: {
            search: {
                handler(value) {
                    if (this.old)
                        this.$store.dispatch('changePage', '1')
                    else
                        this.old = true;
                    this.Url.searchParams.set('search_query', this.search);
                    this.$store.dispatch('changeLoadingStatus', true);
                    this.getAuctions()
                },
            },
            params: {
                handler(value) {
                    this.$store.dispatch('changePage', '1')
                    this.setParams();
                    this.$store.dispatch('changeLoadingStatus', true);
                    this.getAuctions();
                },
                deep: true
            }
        },
        computed: {
            Url: function () {
                return new URL(this.auctionsRoute);
            },
            search: function () {
                return this.$store.state.search_query;
            },
            params: function () {
                return this.$store.state.params;
            },
            page: function () {
                return this.$store.state.page;
            },
            isLogged: function () {
                return this.$store.state.isLogged;
            },
        }
    };

</script>
