<template>
    <tr class="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between car-div-color">
        <td class="d-flex flex-column flex-sm-row border-0">
            <div class="d-flex flex-wrap gap-05 justify-content-center justify-content-sm-between align-items-start w-100">
                <search-params
                    v-for="(item, index) in search.query" :key="index"
                    :param-value="item"
                >
                </search-params>
            </div>
        </td>
        <td class="border-0 d-flex align-items-center justify-content-center justify-content-sm-between">
            <div class="d-flex align-items-center">
                <a class="btn bg-orange py-1 px-3 button-fw" :href="search.search_route" role="button">
                    <span class="text-uppercase text-white">{{ __('auction.search.show') }}</span>
                </a>
            </div>
            <div class="d-flex flex-column px-3 align-items-end justify-content-end">
                <h5 class="font-weight-bolder p-0 m-0 pr-sm-0">{{ search.amount }} {{ __('auction.search.' + (search.amount == 1 ? 'auction' :'auctions')) }}</h5>
                <div class="d-flex pt-3 justify-content-center align-items-end">
                    <div class="pr-2 pb-0 pb-sm-1">
                        <span class="text-muted font-weight-bolder text-uppercase">{{ __('auction.notify') }}</span>
                    </div>
                    <label class="switch mb-1 mb-sm-2">
                        <input id="on" type="checkbox" v-model="notify" v-on:click="notifyChange">
                        <div class="slider postion-relative rounded"></div>
                    </label>
                </div>
            </div>
            <div class="d-flex h-100 align-items-start flex-wrap">
                <a class="btn p-0" v-on:click="deleteSearch" role="button">
                    <i class="far fa-trash-alt fa-lg text-danger"></i>
                </a>
            </div>
            <delete-modal v-if="isDelete" :route="search.destroy_route" :type="typeTranslation" @closed="isDelete = false" @deleted="searchDeleted"></delete-modal>
        </td>
    </tr>
</template>

<script>
    import SearchParams from './SearchParams.vue';
    import DeleteModal from './DeleteModal.vue';
    export default {
        components: {
            SearchParams,
            DeleteModal
        },
        props: {
            search: {
                required: true
            }
        },
        created: function () {
            this.notify = this.search.notify;
        },
        data() {
            return {
                notify: null,
                isDelete: false,
                typeTranslation: this.__('favorites.search')
            };
        },
        methods: {
            deleteSearch() {
                this.isDelete = true;
            },
            searchDeleted() {
                this.$emit('search-deleted', this.search.id);
                Vue.$toast.open({
                            message: this.__('auction.filters.search_deleted'),
                            type: 'warning',
                        });
            },
            notifyChange() {
                axios.patch(this.search.notifications_route)
                    .then(res => {

                    });
            },
        },
    };

</script>
