<template>
  <a
    class="btn text-dark text-center button-fw p-0"
    type="button"
    data-toggle="tooltip"
    data-trigger="hover"
    :title="__('auction.report_comment')"
  >
    <a>
      <i v-if="!isReport" class="far fa-flag pl-1" v-on:click="addReport"></i>
    </a>
    <i v-if="isReport" class="fas fa-flag pl-1 text-red"></i>
    <report-modal
      v-if="isReporting"
      :route="reportRoute"
      @closed="isReporting = false"
      @reported="reported"
    ></report-modal>
  </a>
</template>

<script>
import ReportModal from "./ReportModal.vue";
export default {
  components: {
    ReportModal,
  },
  props: {
    isReportProps: {
      required: true,
    },
    reportRoute: {
      required: true,
    },
  },
  created: function () {
    this.isReport = this.isReportProps;
  },
  data() {
    return {
      isReport: null,
      isReporting: false,
    };
  },
  methods: {
    addReport() {
      this.isReporting = true;
      $("[data-toggle='tooltip']").tooltip('hide');
    },
    reported() {
      this.isReport = true;
      Vue.$toast.open({
        message: this.__("auction.reported_comment"),
        type: "success",
      });
    },
  },
  computed: {
    isLogged: function () {
      return this.$store.state.isLogged;
    },
  },
};
</script>