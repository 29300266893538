<template>
    <div>
        <button v-if="isLogged && !billingRoute" class="btn bg-white d-flex justify-content-center align-items-center border w-100 button-fw" v-on:click="copyToClipBoard">
            <h6 class="p-0 m-0 pr-2">{{ __('affilation.promote_and_earn') }}</h6>
            <img :src="image" alt="coin">
        </button>
        <button v-else-if="isLogged && billingRoute" class="btn bg-white d-flex justify-content-center align-items-center border w-100 button-fw" v-on:click="changePage(billingRoute)">
            <h6 class="p-0 m-0 pr-2">{{ __('affilation.add_payment_method') }}</h6>
            <img :src="image" alt="coin">
        </button>
        <button v-else class="btn bg-white d-flex justify-content-center align-items-center border w-100 button-fw" v-on:click="changePage(loginRoute)">
            <h6  class="p-0 m-0 pr-2" :href="loginRoute">{{ __('affilation.sign_in_to_earn') }}</h6>
            <img :src="image" alt="coin">
        </button>    
    </div>
</template>

<script>
export default {
    props: {
        image: {
            required: true
        },
        link: {
            required: false
        },
        loginRoute: {
            required: true
        },
        billingRoute: {
            required: false
        },
    },
    methods: {
        copyToClipBoard() {
            if(!this.link)
                return;
            const el = document.createElement('textarea');  
            el.value = this.link;                                 
            el.setAttribute('readonly', '');                
            el.style.position = 'absolute';                     
            el.style.left = '-9999px';                      
            document.body.appendChild(el);                  
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
            el.select();                                    
            document.execCommand('copy');                   
            document.body.removeChild(el);                  
            if (selected) {                                 
            document.getSelection().removeAllRanges();    
            document.getSelection().addRange(selected);
            }
            Vue.$toast.open({
                message: this.__('affilation.affiliate_link_copied'),
                type: 'success',
            });   
        },
        changePage(route) {
            location.href = route;
        }
    },
    computed: {
        isLogged: function() {
            return this.$store.state.isLogged;
        }
    }
};
</script>