<template>
    <div id="collapseFilters" class="collapse">
        <div class="bg-gray p-3 px-lg-5 py-lg-4 overflow-auto filters-content border-bottom">
            <div class="filters">

                <!-- make -->
                <div>
                    <label class="font-weight bolder">{{ __('auction.filters.make') }}</label>
                    <select class="form-control border rounded" name="make"
                        @change="setSearch('make', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="item in makes" :key="item" :selected="item === make" :value="item">
                            {{ item }}
                        </option>
                    </select>
                </div>

                <!-- model -->
                <div>
                    <label class="font-weight bolder">{{ __('auction.filters.model') }}</label>
                    <select :disabled="make === null" class="form-control border rounded" name="model"
                        @change="setSearch('model', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="item in models" :key="item" :selected="item === model" :value="item">
                            {{ item }}
                        </option>
                    </select>
                </div>

                <!-- body style -->
                <div>
                    <label> {{ __('auction.body_style') }} </label>
                    <select class="form-control border rounded" name="body_styles"
                        @change="setSearch('body_styles', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(body, index) in bodyStyles" :key="index" :selected="body === body_styles"
                            :value="body">
                            {{ __('auction.filters.' + body.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- transmission -->
                <div>
                    <label>{{ __('auction.filters.transmission') }}</label>
                    <select class="form-control border rounded text-upp" name="transmission"
                        @change="setSearch('transmission', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="item in transmissions" :key="item" :selected="item === transmission" :value="item">
                            {{ __('auction.filters.' + item.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- seller type -->
                <div>
                    <label for="seller_type">{{ __('auction.filters.seller_type') }}</label>
                    <select id="seller_type" class="form-control border rounded" name="seller_type"
                        @change="setSearch('seller_type', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(type, index) in sellerTypes" :key="index" :selected="type === seller_type"
                            :value="type">
                            {{ __('auction.filters.' + type.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- engine type -->
                <div>
                    <label for="engine_type">{{ __('auction.filters.engine_type') }}</label>
                    <select id="engine_type" class="form-control border rounded" name="engine_type"
                        @change="setSearch('engine_type', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(type, index) in engineTypes" :key="index" :selected="type === engine_type"
                            :value="type">
                            {{ __('auction.filters.' + type.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- engine capacity form to -->
                <div class="d-flex flex-column">
                    <label for="capacity_from"> {{ __('auction.filters.engine_capacity') }} (cm³) </label>
                    <div class="d-flex ">
                        <div class="pr-2">
                            <input id="capacity_from" :placeholder="__('auction.filters.bidFrom')"
                                class="form-control border rounded text-left" name="capacity_from" type="text" value=""
                                @change="setSearch('capacity_from', $event.target.value)">
                        </div>

                        <div class="pl-2">
                            <input id="capacity_to" :placeholder="__('auction.filters.bidTo')"
                                class="form-control border rounded text-left" name="capacity_to" type="text" value=""
                                @change="setSearch('capacity_to', $event.target.value)">
                        </div>
                    </div>
                </div>

                <!-- milege from to -->
                <div class="d-flex flex-column">
                    <label for="mileage_from">{{ __('auction.filters.mileage') }}</label>
                    <div class="d-flex ">
                        <div class="pr-2">
                            <input id="mileage_from" :placeholder="__('auction.filters.bidFrom')"
                                class="form-control border rounded text-left" name="mileage_from" type="text" value=""
                                @change="setSearch('mileage_from', $event.target.value)">
                        </div>

                        <div class="pl-2">
                            <input id="mileage_to" :placeholder="__('auction.filters.bidTo')"
                                class="form-control border rounded text-left" name="mileage_to" type="text" value=""
                                @change="setSearch('mileage_to', $event.target.value)">
                        </div>
                    </div>
                </div>
                <!-- control -->
                <div>
                    <label class="font-weight bolder">{{ __('auction.filters.driver_side') }}</label>
                    <select class="form-control border rounded" @change="setSearch('control', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="control in controls" :key="control" :value="control">
                            {{ __('auction.filters.' + control.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- drive -->
                <div>
                    <label class="font-weight bolder">{{ __('auction.filters.drivetrain') }}</label>
                    <select class="form-control border rounded" @change="setSearch('drive', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(item, index) in drives" :key="index" :selected="item === drive" :value="item">
                            {{ __('auction.filters.' + item.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- colour -->
                <div>
                    <label>{{ __('auction.filters.exterior_color') }}</label>
                    <select class="form-control border rounded" name="colour"
                        @change="setSearch('colour', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(item, index) in colors" :key="index" :selected="item === colour" :value="item">
                            {{ __('colors.' + item.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- accept crypto -->
                <div>
                    <label for="crypto">{{ __('auction.filters.crypto_friendly') }}</label>
                    <select id="crypto" v-model="accept_crypto" class="form-control border rounded" name="crypto">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option :value="true">{{ __('auction.filters.yes') }}</option>
                        <option :value="false">{{ __('auction.filters.no') }}</option>
                    </select>
                </div>

                <!-- damage type -->
                <div>
                    <label for="damage">{{ __('auction.filters.damage_type') }}</label>
                    <select id="damage" class="form-control border rounded" name="damage"
                        @change="setSearch('damage_type', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(damage, index) in damageTypes" :key="index" :selected="damage === damage_type"
                            :value="damage">
                            {{ __('auction.filters.' + damage.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- invoice -->
                <div>
                    <label for="invoice">{{ __('auction.filters.invoice') }}</label>
                    <select id="invoice" class="form-control border rounded" name="invoice"
                        @change="setSearch('invoice_type', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(invoice, index) in invoiceTypes" :key="index" :selected="invoice === invoice_type"
                            :value="invoice">
                            {{ __('auction.filters.invoice_' + invoice.toLowerCase()) }}
                        </option>
                    </select>
                </div>


                <!-- price from to -->
                <div class="d-flex flex-column">
                    <label for="bid_from">{{ __('auction.filters.bid') }}</label>
                    <div class="d-flex ">
                        <div class="pr-2">
                            <input id="bid_from" :placeholder="__('auction.filters.bidFrom')"
                                class="form-control border rounded text-left" name="bid_from" type="text" value=""
                                @change="setSearch('bid_from', $event.target.value)">
                        </div>

                        <div class="pl-2">
                            <input id="bid_to" :placeholder="__('auction.filters.bidTo')"
                                class="form-control border rounded text-left" name="bid_to" type="text" value=""
                                @change="setSearch('bid_to', $event.target.value)">
                        </div>
                    </div>
                </div>
                <!-- year from to  -->
                <div class="d-flex flex-column">
                    <label for="year_from">{{ __('auction.filters.yearOfProduction') }}</label>
                    <div class="d-flex ">
                        <div class="pr-2">
                            <input id="year_from" :placeholder="__('auction.filters.yearFrom')"
                                class="form-control border rounded text-left" name="year_from" type="text" value=""
                                @change="setSearch('year_from', $event.target.value)">
                        </div>

                        <div class="pl-2">
                            <input id="year_to" :placeholder="__('auction.filters.yearTo')"
                                class="form-control border rounded text-left" name="year_to" type="text" value=""
                                @change="setSearch('year_to', $event.target.value)">
                        </div>
                    </div>
                </div>
                <!-- minimal price -->
                <div>
                    <label for="minimal_price">{{ __('auction.filters.minimal_price') }}</label>
                    <select id="minimal_price" class="form-control border rounded" name="minimal_price"
                        @change="setSearch('minimal_price', $event.target.value)">
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option v-for="(price, index) in minimalPrice" :key="index" :selected="price === minimal_price"
                            :value="price">
                            {{ __('auction.filters.' + price.toLowerCase()) }}
                        </option>
                    </select>
                </div>
                <!-- Voivodeship -->
                <div>
                    <label>{{ __('auction.filters.country_type') }}</label>
                    <select
                        class="form-control border rounded"
                        name="voivodeship_types"
                        @change="setSearch('voivodeship_types', $event.target.value)"
                    >
                        <option :value="null">{{ __('auction.filters.all') }}</option>
                        <option
                            v-for="(voivode, index) in voivodeshipTypes"
                            :key="index"
                            :selected="voivode === voivodeship_types"
                            :value="voivode"
                        >
                        {{ __('auction.voivodeships.' + voivode.toLowerCase()) }}
                        </option>
                    </select>
                </div>

                <!-- sort by -->
                <div class="d-lg-none">
                    <label for="sort_by">{{ __('auction.filters.sort_by') }}</label>
                    <select id="sort_by" v-model="sort_by" class="form-control border rounded" name="sort_by">
                        <option v-if="!sort_by" selected value="end">{{ __('auction.filters.ending_soon') }}</option>
                        <option v-else value="end">{{ __('auction.filters.ending_soon') }}</option>
                        <option value="created_at">{{ __('auction.filters.latest') }}</option>
                    </select>
                </div>

            </div>

            <div class="d-none d-lg-flex justify-content-end pt-3">
                <button class="btn sell-button bg-black px-3 border-0 text-white font-weight-bold text-uppercase mr-2"
                    @click="resetFilters">
                    {{ __('auction.filters.reset_filters') }}
                </button>
                <button class="btn sell-button bg-dark px-3 border-0 text-white font-weight-bold text-uppercase"
                    @click="closeFilters">
                    {{ __('auction.filters.close_filters') }}
                </button>
            </div>

            <div class="d-lg-none mobile-apply-filters">
                <button class="btn sell-button bg-black px-3 border-0 text-white font-weight-bold text-uppercase mr-2"
                    @click="resetFilters">
                    {{ __('auction.filters.reset_filters') }}
                </button>
                <button class="btn sell-button bg-dark text-uppercase" v-on:click="show">
                    {{ __('auction.filters.close_filters') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        controls: { required: true },
        transmissions: { required: true },
        makes: { required: true },
        modelsRoute: { required: true },
        searchSaveRoute: { required: true },
        sellerTypes: { required: true },
        engineTypes: { required: true },
        drives: { required: true },
        damageTypes: { required: true },
        colors: { required: true },
        mileages: { required: true },
        engineCapacities: { required: true },
        engineCylinders: { required: true },
        bodyStyles: { required: true },
        voivodeshipTypes: { required: true },
        minimalPrice: { required: true },
        country: { required: true },
        invoiceTypes: { required: true },
    },
    data() {
        return this.initialState();
    },
    created() {
        if (this.make)
            this.getModels();
    },
    methods: {
        initialState() {
            return {
                make: this.$store.state.params.make,
                model: this.$store.state.params.model,
                mileage: this.$store.state.params.mileage,
                transmission: this.$store.state.params.transmission,
                seller_type: this.$store.state.params.seller_type,
                engine_type: this.$store.state.params.engine_type,
                engine_capacity: this.$store.state.params.engine_capacity,
                engine_cylinders: this.$store.state.params.drive,
                control: this.$store.state.params.control,
                drive: this.$store.state.params.drive,
                colour: this.$store.state.params.colour,
                accept_crypto: this.$store.state.params.accept_crypto,
                drive_type: this.$store.state.params.drive_type,
                damage_type: this.$store.state.params.damage_type,
                sort_by: this.$store.state.params.sort_by,
                mileage_from: this.$store.state.params.mileage_from,
                mileage_to: this.$store.state.params.mileage_to,
                bid_from: this.$store.state.params.bid_from,
                bid_to: this.$store.state.params.bid_to,
                body_styles: this.$store.state.params.body_styles,
                voivodeship_types: this.$store.state.params.voivodeship_types,
                capacity_from: this.$store.state.params.capacity_from,
                capacity_to: this.$store.state.params.capacity_to,
                year_from: this.$store.state.params.year_from,
                year_to: this.$store.state.params.year_to,
                minimal_price: this.$store.state.params.minimal_price,
                country_type: this.$store.state.params.country_type,
                invoice_type: this.$store.state.params.invoice_type,
                models: null,

            }
        },
        resetFilters() {
            this.$store.commit('CLEAR_FILTERS');
            Object.assign(this.$data, this.initialState());
            let filters = ['bid_to', 'bid_from', 'year_to', 'year_from','capacity_from','capacity_to','mileage_from','mileage_to']
            filters.forEach(element => document.getElementById(element).value = '')
        },
        getModels() {
            let modelsURL = new URL(this.modelsRoute);
            modelsURL.searchParams.set('make', this.make)
            axios.get(modelsURL).then(res => {
                this.models = res.data.models;
            });
        },
        getAuctionsSortBy(sort) {
            this.$store.dispatch('changePage', '1')
            this.$store.dispatch('changeParams', {
                paramName: 'sort_by',
                param: sort
            });
        },
        show() {
            this.$store.dispatch('changeMobileSearch', !this.display);
        },
        closeFilters() {
            document.getElementById('collapseFilters').classList.remove('show');
            document.getElementById('mobileFilters').classList.remove('collapsed')
        },
        setSearch(name, value) {
            this[name] = value === '' ? null : value;
            this.$store.dispatch('changeParams', {
                paramName: name,
                param: this[name]
            });
        }
    },
    computed: {
        params: function () {
            return this.$store.state.params;
        },
        isLogged: function () {
            return this.$store.state.isLogged;
        },
        display: function () {
            return this.$store.state.mobileSearch;
        },
        countries: function () {
            const countryKeys = Object.keys(this.country)
            let country = [];
            for (let i = 0; i <= countryKeys.length - 1; i++) {
            country.push({ id: countryKeys[i], name: this.country[countryKeys[i]] })
        }

        country.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            return nameA.localeCompare(nameB)
        });

        return country;
        }
    },
    watch: {
        make: {
            handler(value) {
                this.$store.dispatch('changeParams', {
                    paramName: 'make',
                    param: this.make
                });
                this.model = null;
                this.$store.dispatch('changeParams', {
                    paramName: 'model',
                    param: this.model
                });
                this.colour = null;
                this.$store.dispatch('changeParams', {
                    paramName: 'colour',
                    param: this.model
                });
                if (this.make) {
                    this.getModels();
                }
            },
        },
        sort_by: {
            handler(value) {
                this.$store.dispatch('changeParams', {
                    paramName: 'sort_by',
                    param: this.sort_by
                });
            },
        },
        accept_crypto: {
            handler(value) {
                this.$store.dispatch('changeParams', {
                    paramName: 'accept_crypto',
                    param: this.accept_crypto
                });
            },
        }
    },
};

</script>
