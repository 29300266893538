<template>
    <div>
        <span class="font-weight-bold" v-if="this.auctionStatusProps == 'published'">{{ __('auction.coming_soon') }}</span>
        <span class="time-span w-100 font-weight-bold" :class="[timeLeftMiliseconds && timeLeftMiliseconds < hourMiliseconds && 'text-danger', mobileAuction && 'text-sm']" v-else>{{timeLeft}}</span>
    </div>

</template>

<script>
export default {
    props: {
        auctionEndProps: {
            required: true
        },
        auctionStatusProps: {
            required: false
        },
        mobileAuction: {
            required: false
        }
    },
    data() {
        return {
            timeLeft: null,
            timerId: null,
            timeLeftMiliseconds: null,
            hourMiliseconds: 7200000,
        }
    },
    methods: {
        updateTime() {
            let newTime = this.end - Date.now();
            if (newTime > 0) {
                let days = Math.floor(newTime / (24 * 60 * 60 * 1000));
                let daysms = newTime % (24 * 60 * 60 * 1000);
                let hours = Math.floor((daysms) / (60 * 60 * 1000))+days*24;
                let hoursms = newTime % (60 * 60 * 1000);
                let minutes = Math.floor((hoursms) / (60 * 1000));
                let minutesms = newTime % (60 * 1000);
                let sec = Math.floor((minutesms) / (1000));
                minutes = minutes > 9 ? minutes : "0" + minutes;
                sec = sec > 9 ? sec : "0" + sec;
                this.timeLeft = days>1?(days+ this.__('auction.days')):(hours>9?hours:"0"+hours)+":"+minutes+":"+sec;
                this.timeLeftMiliseconds = newTime;
            } else {
                this.timeLeft = this.__('auction.ended');
                this.$emit('auction-ended');

                clearInterval(this.timerId)
            }
        },
    },
    computed: {
        end: function () {
            return (new Date(this.auctionEndProps)).getTime();
        },
    },
    watch: {
        end: {
            immediate: true,
            handler(value) {
                if(this.timerId)
                    clearInterval(this.timerId)
                this.updateTime();
                this.timerId = window.setInterval(
                this.updateTime, 1000);
            }
        }
    }
}
</script>
