<template>
    <div
        class="m-0 px-0 justify-content-end" :class="[col, display? 'd-none': 'd-flex']" style="width:40px;">
        <button ref="button" type="button" class="btn p-0 pl-2 webkit" data-toggle="collapse" data-target="#collapseMobile"
            aria-expanded="false" aria-controls="collapseMobile" v-on:click="show">
            <img v-show="!isOpen" :src="img" alt="menu" height="35px" width="35px">
            <i v-show="isOpen" class="fas fa-times fa-lg"></i>
        </button>

    </div>
</template>

<script>
export default {
    props: {
        img: {
            required: true
        },
        col: {
            required: false
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        show() {
            this.isOpen = !this.isOpen;
        }
    },
    watch: {
        display: {
            handler(value) {
                if(this.display && document.getElementById("collapseMobile").classList.contains('show'))
                    this.$refs.button.click();
            }
        }
    },
    computed: {
        display: function () {
            return this.$store.state.mobileSearch;
        },
    }
};
</script>
