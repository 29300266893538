<template>
    <div>
        <ConfirmDialog :group="id"></ConfirmDialog>

        <button class="btn bg-red text-white py-2" @click="del()">
            <i class="fas fa-trash"></i>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        url: String,
    },
	methods: {
        del() {
            this.$confirm.require({
                message: 'Czy na pewno chcesz usunąć dany rekord',
                header: 'Powierdzenie',
                icon: 'pi pi-exclamation-triangle',
                group: this.id,
                acceptClass: 'p-button-danger',
                acceptLabel: 'Tak',
                rejectLabel: 'Nie',
                accept: () => {
                    axios.post(this.url,{
                        _method: 'DELETE'
                    })
                    .then(() => {
                        location.reload();
                        Vue.$toast.open({
                            message: "Udało się usunąć rekord",
                            type: 'success',
                        });
                    })
                    .catch(() => {
                        Vue.$toast.open({
                            message: "Nie udało się usunąć rekordu",
                            type: 'error',
                        });
                    })
                },
            });
        },
    }
}
</script>

