<template>
    <button
        type="button"
        @click="open"
        class="col-11 col-lg-5 btn btn-primary bg-black d-inline-flex py-2 my-2 border-0 font-weight-bolder w-100 justify-content-center align-items-center rounded"
    >
        <i class="pi pi-facebook mr-2" style="font-size: 1.2rem;" />
        <span class="" style="">{{ text }}</span>
    </button>
</template>

<script>
    export default {
        props: {
            href: {
                required: true,
                type: String
            },
            text: {
                required: true
            }
        },
        methods:{
            open(){
                window.location = this.href
            }
        }
    };

</script>

