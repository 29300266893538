<template>
    <div class="d-flex align-items-center">
        <div>{{likesProps}}</div>
        <i v-if="!isLike" class="far fa-thumbs-up pl-1 favourite-icon addFav" v-on:click="addLike"></i>
        <i v-if="isLike" class="fas fa-thumbs-up pl-1 text-orange deleteFav" v-on:click="deleteLike"></i>
    </div>
</template>

<script>
export default {
    props: {
        isLikeProps: {
            required: true
        },
        likeRoute: {
            required: true
        },
        likesProps: {
            required: true
        }
    },
    created: function () {
        this.isLike = this.isLikeProps;
    },
    data() {
        return {
            isLike: null
        };
    },
    methods: {
        addLike() {
            if(this.isLogged)
                axios.post(this.likeRoute).then(res => {
                    this.isLike = true;
                    this.$emit('likes-changed', 1);
                });
        },
        deleteLike() {
            axios.post(this.likeRoute, {
                    _method: 'DELETE'
                }).then(res => {
                    this.isLike = false;
                    this.$emit('likes-changed', -1);
            });
        }
    },
    computed: {
        isLogged: function() {
            return this.$store.state.isLogged;
        }
    }
};
</script>