<template>
  <!--nav tabs -->
  <div class="col-12 px-0 px-md-3">
    <div class="">
      <ul
        class="
          nav nav-pills
          mt-0
          mb-3
          d-flex
          justify-content-center justify-content-md-start
          categories-pills
        "
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item">
          <a
            class="nav-link mr-2"
            :class="status == 'paid' ? 'active' : ''"
            id="pills-paid-tab"
            data-toggle="pill"
            href="#pills-paid"
            role="tab"
            aria-controls="pills-paid"
            aria-selected="false"
            v-on:click="changeStatus('paid')"
          >
            <h6 class="p-0 m-0">
              {{ __("affilation.paid_affiliations") }}
            </h6>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link mr-2"
            :class="status == 'ongoing' ? 'active' : ''"
            id="pills-ongoing-tab"
            data-toggle="pill"
            href="#pills-ongoing"
            role="tab"
            aria-controls="pills-ongoing"
            aria-selected="false"
            v-on:click="changeStatus('ongoing')"
          >
            <h6 class="p-0 m-0">
              {{ __("affilation.ongoing_affiliations") }}
            </h6>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="status == 'error' ? 'active' : ''"
            id="pills-error-tab"
            data-toggle="pill"
            href="#pills-error"
            role="tab"
            aria-controls="pills-error"
            aria-selected="false"
            v-on:click="changeStatus('error')"
          >
            <h6 class="p-0 m-0">
              {{ __("affilation.not_accepted") }}
            </h6>
          </a>
        </li>
      </ul>
    </div>
    <div class="card border bg-gray p-4 tab-content">
      <div class="tab-pane fade show active ">
        <affiliations
          class="mw-100"
          :affiliations-route="currentRoute"
          :status="status"
        ></affiliations>
      </div>
    </div>
  </div>
</template>

<script>
import Affiliations from "./Affiliations.vue";
export default {
  components: { Affiliations },
  props: {
    affiliationsRoute: {
      required: true,
    },
  },
  data() {
    return {
      currentRoute: null,
      status: null,
    };
  },
  created: function () {
    this.currentRoute = this.affiliationsRoute;
    if (new URL(window.location.href).searchParams.get("status")) {
      let tmp = new URL(this.affiliationsRoute);
      tmp.searchParams.set(
        "status",
        new URL(window.location.href).searchParams.get("status")
      );
      this.status = new URL(window.location.href).searchParams.get("status");
      this.currentRoute = tmp.href;
    }
    if (new URL(window.location.href).searchParams.get("page")) {
      let tmp = new URL(this.affiliationsRoute);
      tmp.searchParams.set(
        "page",
        new URL(window.location.href).searchParams.get("page")
      );
      this.currentRoute = tmp.href;
    }
    if (!this.status) this.status = "paid";
  },
  methods: {
    changeStatus(status) {
      this.status = status;
      let tmp = new URL(this.affiliationsRoute);
      tmp.searchParams.set("status", status);
      this.currentRoute = tmp.href;
    },
  },
  computed: {
    Url: function () {
      return new URL(window.location.href);
    },
  },
};
</script>
