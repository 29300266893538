<template>
    <form @submit.prevent>
        <div>
            <textarea ref="input" class="w-100 rounded border p-2" cols="30" rows="5" v-model="content" maxlength="1000"></textarea>
        </div>
        <div v-if="!reply" class="d-flex justify-content-end pt-3">
            <button v-on:click="addQuestion" class="btn border-0 letter-0 bg-black py-1 px-3 text-white button-fw">
                {{ __('auction.ask_seller') }}
            </button>
        </div>
        <div v-else class="d-flex justify-content-end mt-2 pr-2 py-1 pr-lg-0">
            <button v-on:click="removeReply" class="btn mr-2 border-0 letter-0 bg-red text-white button-fw">
                <i class="fas fa-times"></i>
            </button>
            <button v-if="isQuestion" v-on:click="addReply" class="btn border-0 letter-0 bg-green py-1 px-3 text-white button-fw">
                {{ __('auction.reply_to_question') }}
            </button>
            <button v-else v-on:click="addReply" class="btn border-0 py-1 letter-0 bg-green py-1 px-3 text-white button-fw">
                {{ __('auction.reply') }}
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        commentRoute: {
            type: String,
            required: true
        },
        questionRoute: {
            type: String,
            required: true
        },
        replyRoute: {
            type: String,
            required: true
        },
        reply: {
            required: false
        },
        isQuestion: {
            required: false
        },
        focus: {
            required: true
        },
        isAdmin: {
            required: true
        },
        isSeller: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return{
            content: "",
        }
    },
    methods: {
        addQuestion() {
            if(this.content!="")
                axios.post(this.questionRoute, {content: this.content})
                .then(response => {
                    if(this.isSeller || this.isAdmin) {
                        this.$emit('question-added', response.data.data);
                    }
                    this.content="";
                    Vue.$toast.open({
                            message: this.__('auction.question_added'),
                            type: 'success',
                        });
                })
                .catch(function(error) {

                });
            else {
                Vue.$toast.open({
                    message: this.__('auction.toast.box_empty'),
                    type: 'error',
                });
            }
        },
        addReply() {
            if(this.content!="")
                axios.post(this.replyRoute, {content: this.content, reply_to: this.reply.id})
                .then(response => {
                    if(this.reply.isQuestion) {
                        if(this.isSeller || this.isAdmin) {
                            this.$emit('question-added', response.data.data);
                        }
                        Vue.$toast.open({
                            message: this.__('auction.added_reply'),
                            type: 'success',
                        });
                    } else {
                        if(this.isSeller || this.isAdmin) {
                            this.$emit('comment-added', response.data.data);
                        }
                        Vue.$toast.open({
                            message: this.__('auction.added_reply_to_comment'),
                            type: 'success',
                        });
                    }
                    this.content="";

                })
                .catch(function(error) {

                });
            else {
                Vue.$toast.open({
                    message: this.__('auction.added_reply_to_comment'),
                    type: 'error',
                });
            }
        },
        removeReply() {
            this.$emit('reply-clear');
            this.content="";
        }
    },
    watch: {
            focus: {
                handler(value) {
                   this.$refs.input.focus();
                },
            },
        },
};
</script>
