<template>
  <a
    v-on:click="show"
    class="
      btn
      rounded-0
      text-uppercase
      d-flex
      flex-column
      text-muted
      justify-content-center
    "
  >
    <i class="fas fa-search"></i>
    <span>{{ __("auction.search_bar") }}</span>
  </a>
</template>

<script>
export default {
  methods: {
    show() {
      this.$store.dispatch("changeMobileSearch", !this.display);
    },
  },
  watch: {
    display: {
      handler(value) {
        if (this.display) {
          document.getElementById("searchDiv").classList.remove("d-none");
          document.getElementById("searchDiv").classList.add("d-flex");
          document.getElementById("searchMobile").focus();

          document.getElementById("mobileFilters").classList.remove("d-none");
          document.getElementById("mobileFilters").classList.add("d-flex");

          document.getElementById("mobileLanguage").classList.remove("d-flex");
          document.getElementById("mobileLanguage").classList.add("d-none");

          document.getElementById("mobileLogo").classList.add("d-none");

          document.getElementById("divFilters").classList.remove("d-none");
          document.getElementById("divFilters").classList.add("d-flex");

          document
            .getElementById("mobileCloseSearch")
            .classList.remove("d-none");
          document.getElementById("mobileCloseSearch").classList.add("d-flex");
        } else {
          document.getElementById("searchDiv").classList.remove("d-flex");
          document.getElementById("searchDiv").classList.add("d-none");

          if (
            document
              .getElementById("mobileFilters")
              .getAttribute("aria-expanded") == "true"
          )
            document.getElementById("mobileFilters").click();

          document.getElementById("mobileFilters").classList.remove("d-flex");
          document.getElementById("mobileFilters").classList.add("d-none");

          document.getElementById("mobileLanguage").classList.remove("d-none");
          document.getElementById("mobileLanguage").classList.add("d-flex");

          document.getElementById("mobileLogo").classList.remove("d-none");

          document.getElementById("divFilters").classList.remove("d-flex");
          document.getElementById("divFilters").classList.add("d-none");

          document
            .getElementById("mobileCloseSearch")
            .classList.remove("d-flex");
          document.getElementById("mobileCloseSearch").classList.add("d-none");
        }
      },
    },
  },
  computed: {
    display: function () {
      return this.$store.state.mobileSearch;
    },
  },
};
</script>