<template>
 <div class="conversation-body d-flex justify-content-end flex-column p-3">
    <div class="messages" ref="scroll">
        <message v-for="message in messages" :key="message.id" :message="message"></message>
    </div>
    <add-message 
        :message-route="messageRoute"
        @message-added="addMessage"
    ></add-message>
</div>
</template>

<script>
    import AddMessage from './AddMessage.vue';
    import Message from './Message.vue';
    export default {
        components: {
            AddMessage,
            Message
        },
        props: {
            conversation: {
                required: true
            },
            messageRoute: {
                required: true
            },
            messagesRoute: {
                type: String,
                required: true
            },
            channel: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                messages: {},
                nextPageMessages: null,
                avatar: '/images/avatar.png',
                reply: null,
                channelD: null,
            };
        },
        methods: {
            addMessage(data) {
                this.messages.push(data);
                this.$emit('message-added', this.conversation);
            },
            getMessages() {
                axios.get(this.messagesRoute).then(res => {
                    this.messages = res.data.messages;
                });
            },
            listenNewMessages() {
                Echo.private(this.channel)
                    .listen('MessageAdded', (data) => {
                        this.messages.push(data.data);
                        axios.post(data.data.markAsRead);
                    })
            },
            scrollDown() {
                this.$nextTick(function () {
                    this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
                });
            }
        },
        mounted() {
            this.getMessages();
            this.listenNewMessages();
            this.channelD = this.channel
            // this.$refs.scroll.addEventListener('scroll', e=> {
            //     if(this.nextPageMessages)
            //         if(this.$refs.scroll.scrollTop <= 100)
            //             this.loadMoreMessages();
            // });
        },
        watch: {
            messages: {
                handler(value) {
                    this.scrollDown();
                }
            },
            messagesRoute: {
                handler(value) {
                    Echo.private(this.channelD)
                        .stopListening('MessageAdded');
                    this.channelD = this.channel
                    this.getMessages();
                    this.listenNewMessages();
                }
            }
        }
    };

</script>
