<template>
    <div class="upload-block items cursor-pointer"
         :title=" __('auction.click_here_to_upload')"
         @click="doChooseFile"
    >
        <div class="items">
            <svg class="bi bi-plus-lg" fill="currentColor" height="48" viewBox="0 0 16 16" width="48"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                      fill-rule="evenodd"/>
            </svg>
            <div class="d-relative">
                {{ __('draft_form.add_photos') }}
            </div>
        </div>
        <input v-show="false"
               :id="generatedName"
               :accept="fileRules"
               type="file"
               @change="uploadSelectedFile"
        />
    </div>
</template>

<script>
export default {
    name: "UploadBlock",
    props: {
        fileRules: String,
        acceptedExtensions: Array,
        limit: Number,
        totalFiles: Number,
    },

    data: () => ({
        uploaderDOM: null,
        isUpload: false,
        generatedName: "",
        isDimensional: false,
    }),

    methods: {
        /**
         */
        doChooseFile() {
            if (this.limit > 0 && this.limit <= this.totalFiles)
                return alert( this.__('auction.gallery_limit') + ' ' + `${this.limit}`);
            this.uploaderDOM.click();
        },

        /**
         * [EVENT] When users selected a file
         * @param {{target: {files: FileList}}} e
         */
        uploadSelectedFile(e) {
            const file = e.target.files.item(0)
            const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1)
            if (file.size > 4096000) return alert( this.__('auction.image_to_big')+ " 4 MB")

            if (this.acceptedExtensions.indexOf(fileExtension) < 0) {
                alert(this.__('auction.format_notsupported'));
                return this.clearFile();
            }

            this.afterUploadedFileSuccessfully(file)
        },

        afterUploadedFileSuccessfully(file) {
            this.$emit('uploaded', file)
            this.clearFile()
        },

        async addImageProcess(img) {
            return await new Promise((resolve, reject) => {
                img.onload = () => resolve(img)
                img.onerror = reject
            })
        },

        clearFile() {
            this.uploaderDOM.value = null
        },
        generate() {
            return Math.random().toString(36).slice(2);
        },
        getTitle() {
            return this.__('auction.click_here_to_upload')
        }
    },
    beforeMount() {
        this.generatedName = this.generate()
    },

    mounted() {
        this.uploaderDOM = document.getElementById(this.generatedName)
    },
}
</script>

<style scoped>
.items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
