<template>
    <form @submit.prevent>
        <div class="d-lg-flex send-div align-items-center pt-3">
            <input type="text" class="w-100 py-1 px-2" maxlength="2500" :placeholder="__('conversation.type_sth')" v-model="message">
            <button v-on:click="addMessage" class="btn w-100 w-lg-auto bg-black border-0 py-1 text-uppercase button-fw text-white">{{ __('conversation.send') }}</button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        messageRoute: {
            type: String,
            required: true
        },
    },
    data() {
        return{
            message: "",
        }
    },
    methods: {
        addMessage() {
            axios.post(this.messageRoute, {message: this.message})
            .then(response => {
                this.$emit('message-added', response.data.data);
                this.message="";
            })
            .catch(function(error) {
                
            });
            
        },
    }
};
</script>