<template>
    <div class="vue-uploader">
        <input :id="inputName"
               :name="'images.' + inputName+'[]'"
               class="d-none form-control"
               multiple
               type="file"
        >
        <draggable
            :list="value"
            class="row draggable"
            ghost-class="ghost"
            handle=".item-block"
            @change="saveImage()"
        >
            <ItemBlock v-for="fileInfo in value"
                       :key="fileInfo.lastModified"
                       :file-info="fileInfo"
                       @delete="doDeleteFile"
            />

            <ItemUploadingBlock
                v-if="uploadingFile"
                :file="uploadingFile"
            />

            <div class="d-relative col-6 col-md-4 col-lg-2 my-2" slot="footer" v-show="value.length < maxPhotos">
                <UploadBlock
                    :accepted-extensions="acceptedExtensions"
                    :file-rules="fileRules"
                    :limit="maxPhotos"
                    :total-files="value.length"
                    class="upload-block rounded"
                    @uploaded="handleUploadedFile"
                    @uploading="handleUploadingFile"
                    @upload-failed="handleUploadFailed"
                />
            </div>
        </draggable>


    </div>
</template>

<script>
import ItemBlock from "./ItemBlock"
import UploadBlock from "./UploadBlock";
import draggable from 'vuedraggable'
import ItemUploadingBlock from "./ItemUploadingBlock";
import translation from "../plugins/translation";

export default {
    components: {
        ItemUploadingBlock,
        UploadBlock,
        ItemBlock,
        draggable
    },
    props: {
        images: {
            required: false
        },
        inputName: {
            type: String,
            required: true
        },
        maxPhotos: {
            type: Number,
            default: 5 // 0 => unlimited
        },
        formMode: {
            type: Boolean,
            default: true
        },
        fileRules: {
            type: String,
            default: "image/jpg, image/jpeg, image/png, image/svg",
        },
        acceptedExtensions: {
            type: Array,
            default: () => ['jpg', 'png', 'jpeg', 'svg']
        },
        diskConfig: {
            required: true,
        }
    },
    model: {
        prop: "value",
        event: "change"
    },
    watch: {
        value(val) {
            if (!val || !val.forEach) {
                val = [];
            }
            this.setValue(val);
            this.saveImage()

        },
    },
    data: () => ({
        uploadingFile: null,
        generatedName: '',
        value: [],
        storageUrl: process.env.VUE_APP_STORAGE_URL,
    }),
    methods: {
        doDeleteFile(file) {
            const filtered = this.value.filter(function (el) {
                return el.name != file.name;
            });

            this.value = filtered
            this.setValue(filtered)

        },
        setValue(val) {
            this.$emit('change', val);
        },
        handleUploadingFile(fileInfo) {
            this.uploadingFile = fileInfo
        },
        handleUploadedFile(fileInfo) {
            const dataTransfer = new DataTransfer();

            for (let i = 0; i < this.value.length; i++) {
                dataTransfer.items.add(this.value[i]);
                if (dataTransfer.files[i].lastModified === fileInfo.lastModified)
                    return alert( this.__('auction.image_duplicate'));
            }

            this.uploadingFile = null
            // add to the big value
            this.value.push(fileInfo)
            this.setValue(this.value)
        },
        saveImage() {
            const fileInput = document.getElementById(this.inputName);
            const dataTransfer = new DataTransfer();

            for (let i = 0; i < this.value.length; i++)
                dataTransfer.items.add(this.value[i]);
            fileInput.files = dataTransfer.files;
        },
        handleUploadFailed() {
            this.uploadingFile = false
        },
        getTypeImage(str) {
            return str.substr(str.indexOf('.')).substring(1);
        },
        generate() {
            return Math.random().toString(36).slice(2);
        }
    },
    beforeMount() {
        this.generatedName = this.generate()
    },
    mounted() {
        this.images.forEach(el => {
            let blob = null
            let self = this;
            let xhr = new XMLHttpRequest()
            xhr.open("GET", ( this.diskConfig.driver === 's3'? this.diskConfig.url + '/': storageUrl ) + el.url)
            xhr.responseType = "blob"

            xhr.onload = function () {
                blob = xhr.response
                blob = blob.slice(0, blob.size, 'type/' + el.url.substr(el.url.indexOf('.')).substring(1))

                let file = new File([blob], el.url.replace("photos/", ""),
                    {
                        type: 'image/' + el.url.substr(el.url.indexOf('.')).substring(1)
                    }
                );

                self.value.push(file)
                self.saveImage()

            }
            xhr.send()

        })

    }

}
</script>


<style scoped>
.draggable {
    border: 1px solid #D2D2D2 !important;
    border-radius: 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.upload-block{
    height: 199px;
    width: 184.5px;
    justify-content: center;
    background-image: url('/images/transparent_logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0,0,0,.20);
    vertical-align: middle;
    opacity: 0.7;

}

</style>
