<template>
    <div>
        <Galleria
            :circular="true"
            :activeIndex.sync="typeImage === 'all' ? activeIndex : activeIndexDefault"
            :numVisible="5"
            :responsiveOptions="responsiveOptions"
            :showItemNavigators="smallGallery == 'true'? false : true"
            :showThumbnails="false"
            :value=" typeImage === 'all' ? images : filteredImages"
        >
            <template #item="slotProps">
                <h4 v-if="smallGallery == 'true' " class="position-absolute p-0 m-0 text-white text-uppercase d-none d-lg-flex font-weight-bolder" style="z-index: 10; font-size: 14px;">
                    {{ nameGallery }}
                </h4>
                <div class="text-center w-100" :class="smallGallery == 'true'?  'slotImageSmall' : 'slotImage' " @click="displayBasic = true; smallGallery == 'true'? typeImage=nameGallery.toString() : typeImage='all' " >
                    <img class="pointer w-100 h-100" style="object-fit: cover; object-position: center;"  :alt="slotProps.item.alt" :src="diskConfig.url +'/'+ slotProps.item.itemImageSrc" :style="smallGallery == 'true'? 'filter: brightness(30%);':''  " />
                    <img class="inner-image" style="filter: opacity(50%); " :style="smallGallery == 'true'? ' ' : ''  " src="/images/logo_footer.svg">
                </div>
            </template>
        </Galleria>

        <Galleria
            :circular="true"
            :activeIndex.sync="activeIndex"
            :fullScreen="true"
            :numVisible="7"
            :responsiveOptions="responsiveOptions"
            :showIndicators="false"
            :showItemNavigators="true"
            :showThumbnails="false"
            :value="typeImage === 'all'? images: filteredImages"
            :visible.sync="displayBasic"
            ref="myid"
            containerStyle="max-width: 850px"
        >
            <template #item="slotProps" >
                <img :alt="slotProps.item.alt" :src="diskConfig.url +'/'+ slotProps.item.itemImageSrc" style="width: 100%; display: block;" />
                <img class="inner-image" style="filter: opacity(50%); " :style="smallGallery == 'true'? ' ' : ''  " src="/images/logo_footer.svg">
            </template>

            <template #header>
                <div class="header-gallery">
                    <div class="d-flex flex-row justify-content-center ">
                        <div class="p-2 btn-nav">
                            <a class="btn bg-gray text-decoration-none border d-block" href="#interior1" @click="typeImage === 'interior'? typeImage ='all' :   typeImage='interior' " >
                                <span class="p-0 m-0 font-weight-bolder m-0 ">{{ auctionInterior }}</span>
                            </a>
                        </div>
                        <div class="p-2 btn-nav">
                            <a class="btn bg-gray text-decoration-none border d-block" href="#damage1" @click="typeImage === 'mechanical'? typeImage ='all' : typeImage='mechanical'">
                                <span class="p-0 m-0 font-weight-bolder m-0">{{ auctionMechanical }}</span>
                            </a>
                        </div>
                        <div class="p-2 btn-nav">
                            <a class="btn bg-gray text-decoration-none border d-block" href="#exterior1" @click="typeImage === 'outside'? typeImage ='all' : typeImage='outside'">
                                <span class="p-0 m-0 font-weight-bolder m-0">{{ auctionExterior }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </Galleria>
    </div>
</template>

<script>
export default {
    props: {
        auctionExterior: String,
        auctionInterior: String,
        auctionMechanical: String,
        auctionDamage: String,
        imagesArr: {},
        mutableTypeImage: String,
        smallGallery: false,
        nameGallery: String,
        diskConfig: {
            required: false,
            type: [],
        }
    },
    data() {
        return {
            images: [],
            fullScreen: false,
            activeIndex: 0,
            activeIndexDefault: 0,
            filteredImages: {},
            typeImage: this.mutableTypeImage,
            responsiveOptions: [
                {
                    breakpoint: '1500px',
                    numVisible: 5
                },
                {
                    breakpoint: '1024px',
                    numVisible: 3
                },
                {
                    breakpoint: '768px',
                    numVisible: 2
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
            displayBasic: false,

        }
    },
    beforeMount() {

        for (let i =0; i < this.imagesArr.length; i++)
        {
            this.images.push({
                itemImageSrc: this.imagesArr[i].url,
                thumbnailImageSrc: this.imagesArr[i].url,
                alt: this.imagesArr[i].attribute,
                title: this.imagesArr[i].attribute,
                type: this.imagesArr[i].attribute,
            })
        }

        switch(this.mutableTypeImage) {
            case 'interior': this.filtrImage('interior'); break;
            case 'exterior': this.filtrImage('outside'); break;
            case 'mechanical': this.filtrImage('mechanical'); break;
        }

    },
    mounted() {
            let rootElement = this.$el;
            let childElement = this.$refs.myid;
    },
    methods: {
        imageClick(index) {
            this.activeIndex = index;
            this.displayCustom = true;
        },
        filtrImage(type) {
            const acceptedValues = []
            acceptedValues.push(type)
            const myObject = this.images;

            let temp = Object.keys(myObject).reduce(function(r, e) {
                if (acceptedValues.includes(myObject[e].type)) r[e] = myObject[e]
                return r;
            }, {})
            this.filteredImages = Object.values(temp);
            this.activeIndex = 0;

        }
    },
    watch: {
        typeImage: function(newVal, oldVal) { // watch it

            this.activeIndex = 0;
            if (newVal === 'interior')
            {
                this.filtrImage(newVal);
            } else if (newVal === 'outside') {
                this.filtrImage('outside');
            } else if (newVal === 'mechanical') {
                this.filtrImage(newVal);
            }
        },
    }
}


</script>

<style lang="scss" scoped>
::v-deep {
    .custom-galleria {
        &.fullscreen {
            display: flex;
            flex-direction: column;

            .p-galleria-content {
                flex-grow: 1;
                justify-content: center;
            }
        }

        .p-galleria-content {
            position: relative;
        }

        .p-galleria-thumbnail-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .p-galleria-thumbnail-items-container {
            width: 100%;
        }

        .custom-galleria-footer {
            display: flex;
            align-items: center;
            background-color: rgba(0, 0, 0, .9);
            color: #ffffff;

            > button {
                background-color: transparent;
                color: #ffffff;
                border: 0 none;
                border-radius: 0;
                margin: .2rem 0;

                &.fullscreen-button {
                    margin-left: auto;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
        .title-container {
            > span {
                font-size: .9rem;
                padding-left: .829rem;

                &.title {
                    font-weight: bold;
                }
            }
        }
    }
    .p-galleria .p-galleria-close {
        z-index: 10000;
    }
}

.slotImage {
    position: relative;
    height: 428px;
    overflow: hidden;

    @media (min-width: 992px) {
        max-width: 788px;
    }

    @media (max-width: 1200px) {
        height: 368px;
    }

    @media (max-width: 600px) {
        height: 300px;
    }

    @media (max-width: 375px) {
        height: 250px;
    }
}

.slotImageSmall {
    position: relative;
    height: 140px;
    overflow: hidden;

    @media (max-width: 1200px) {
        height: 120px;
    }
}

.btn-nav {
    width: 220px;
}

@media (max-width: 800px) {
    .btn-nav {
        width: 150px;
    }
}

.inner-image {
    position: absolute;
    top: 10px;
    left: 10px;
    width:5%;
}

</style>
