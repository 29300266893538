<template>
  <div class="card-body p-0">
    <div v-if="sums" class="container px-0">
      <h3 class="p-0 m-0 px-3 font-weight-bolder text-center text-lg-left">
        {{ __("affilation.your_earned_money") }}:
      </h3>
      <h5 class="pt-4 px-3 text-center text-lg-left">
        <span
          class="font-weight-normal"
          v-for="sum in sums"
          :key="sum.currency"
          :sum="sum"
          >{{ numberWithSpaces(sum.money) }} {{ sum.currency
          }}<span
            v-if="
              sum.currency !=
              Object.entries(sums)[Object.keys(sums).length - 1][0]
            "
            >,
          </span></span
        >
      </h5>
    </div>
    <table class="table pt-3">
      <affiliation
        v-for="affiliation in affiliations"
        :key="affiliation.id"
        :affiliation="affiliation"
      ></affiliation>
    </table>
    <nav class="d-flex justify-content-center" v-if="total > perPage">
      <ul class="pagination">
        <li
          v-for="link in links"
          :key="link.label"
          class="page-item"
          :class="[
            link.active && 'active',
            page === 1 && link.label === 'Prev' && 'disable',
            page === Math.ceil(total / perPage) &&
              link.label === 'Next' &&
              'disable',
          ]"
        >
          <a
            v-if="page == link.label || link.url == null"
            class="btn page-link"
          >
            <i
              v-if="link.label === 'Prev'"
              class="fas fa-chevron-left fa-sm pr-1"
            ></i>
            {{ link.label }}
            <i
              v-if="link.label === 'Next'"
              class="fas fa-chevron-right fa-sm pl-1"
            ></i>
          </a>
          <a v-else class="btn page-link" v-on:click="changePage(link.url)">
            <i
              v-if="link.label === 'Prev'"
              class="fas fa-chevron-left fa-sm pr-1"
            ></i>
            {{ link.label }}
            <i
              v-if="link.label === 'Next'"
              class="fas fa-chevron-right fa-sm pl-1"
            ></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Affiliation from "./Affiliation.vue";
export default {
  components: { Affiliation },
  props: {
    affiliationsRoute: {
      required: true,
    },
    status: {
      required: true,
    },
  },
  data() {
    return {
      affiliations: {},
      currentRoute: null,
      links: null,
      currentPage: null,
      total: 0,
      perPage: 24,
      sums: null,
    };
  },
  created: function () {
    this.currentRoute = this.affiliationsRoute;
    if (new URL(window.location.href).searchParams.get("page")) {
      let tmp = new URL(this.currentRoute);
      tmp.searchParams.set(
        "page",
        new URL(window.location.href).searchParams.get("page")
      );
      this.currentRoute = tmp.href;
    }
    if (new URL(window.location.href).searchParams.get("status")) {
      let tmp = new URL(this.currentRoute);
      tmp.searchParams.set(
        "status",
        new URL(window.location.href).searchParams.get("status")
      );
      this.currentRoute = tmp.href;
    } else this.currentRoute = this.affiliationsRoute;
  },
  methods: {
    changePage(url) {
      let tmp = new URL(url);
      tmp.searchParams.set("status", this.status);
      this.currentRoute = tmp.href;
      this.Url.searchParams.set("page", new URL(url).searchParams.get("page"));
      window.history.pushState(null, null, this.Url);
    },
    numberWithSpaces(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      if (parts.length > 1) if (parts[1].length == 1) parts[1] += "0";
      return parts.join(".");
    },
  },
  watch: {
    currentRoute: function () {
      axios.get(this.currentRoute).then((res) => {
        this.affiliations = res.data.affiliations.data;
        this.links = res.data.affiliations.links;
        this.currentPage = res.data.affiliations.current_page;
        this.total = res.data.affiliations.total;
        this.perPage = res.data.affiliations.per_page;
        this.sums = res.data.affiliations.sums;
      });
    },
    status: function () {
      let tmp = new URL(this.currentRoute);
      tmp.searchParams.set("status", this.status);
      tmp.searchParams.set("page", 1);
      this.currentRoute = tmp.href;
      this.Url.searchParams.set("status", this.status);
      this.Url.searchParams.set("page", 1);
      window.history.pushState(null, null, this.Url);
    },
  },
  computed: {
    Url: function () {
      return new URL(window.location.href);
    },
  },
};
</script>
