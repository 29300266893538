var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body p-0"},[(_vm.sums)?_c('div',{staticClass:"container px-0"},[_c('h3',{staticClass:"p-0 m-0 px-3 font-weight-bolder text-center text-lg-left"},[_vm._v("\n      "+_vm._s(_vm.__("affilation.your_earned_money"))+":\n    ")]),_vm._v(" "),_c('h5',{staticClass:"pt-4 px-3 text-center text-lg-left"},_vm._l((_vm.sums),function(sum){return _c('span',{key:sum.currency,staticClass:"font-weight-normal",attrs:{"sum":sum}},[_vm._v(_vm._s(_vm.numberWithSpaces(sum.money))+" "+_vm._s(sum.currency)),(
            sum.currency !=
            Object.entries(_vm.sums)[Object.keys(_vm.sums).length - 1][0]
          )?_c('span',[_vm._v(",\n        ")]):_vm._e()])}),0)]):_vm._e(),_vm._v(" "),_c('table',{staticClass:"table pt-3"},_vm._l((_vm.affiliations),function(affiliation){return _c('affiliation',{key:affiliation.id,attrs:{"affiliation":affiliation}})}),1),_vm._v(" "),(_vm.total > _vm.perPage)?_c('nav',{staticClass:"d-flex justify-content-center"},[_c('ul',{staticClass:"pagination"},_vm._l((_vm.links),function(link){return _c('li',{key:link.label,staticClass:"page-item",class:[
          link.active && 'active',
          _vm.page === 1 && link.label === 'Prev' && 'disable',
          _vm.page === Math.ceil(_vm.total / _vm.perPage) &&
            link.label === 'Next' &&
            'disable',
        ]},[(_vm.page == link.label || link.url == null)?_c('a',{staticClass:"btn page-link"},[(link.label === 'Prev')?_c('i',{staticClass:"fas fa-chevron-left fa-sm pr-1"}):_vm._e(),_vm._v("\n          "+_vm._s(link.label)+"\n          "),(link.label === 'Next')?_c('i',{staticClass:"fas fa-chevron-right fa-sm pl-1"}):_vm._e()]):_c('a',{staticClass:"btn page-link",on:{"click":function($event){return _vm.changePage(link.url)}}},[(link.label === 'Prev')?_c('i',{staticClass:"fas fa-chevron-left fa-sm pr-1"}):_vm._e(),_vm._v("\n          "+_vm._s(link.label)+"\n          "),(link.label === 'Next')?_c('i',{staticClass:"fas fa-chevron-right fa-sm pl-1"}):_vm._e()])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }