<template>
    <div class="card-body p-0">
          <table class="table pt-3">
            <bid-to-confirm v-for="bid in bids" :key="bid.id" :bid="bid" :stripe-public-key="stripePublicKey"
                @bid-confirmed="bidConfirmed"></bid-to-confirm>
            <tr v-if="total == 0">
                <td class="border-0 px-3 py-0 text-center text-md-left">
                    <span>{{ __('auction.no_results') }}</span>
                </td>
            </tr>
        </table>
        <nav class="d-flex justify-content-center" v-if="total > perPage">
            <ul class="pagination">
                <li v-for="link in links" :key="link.label" class="page-item" :class="[link.active && 'active', page === 1 && link.label === 'Prev' && 'disable', page === Math.ceil(total / perPage) && link.label === 'Next' && 'disable' ]">
                    <a v-if="page == link.label || link.url == null" class="btn page-link">
                        <i v-if="link.label === 'Prev'" class="fas fa-chevron-left fa-sm pr-1"></i>
                        {{link.label}}
                        <i v-if="link.label === 'Next'" class="fas fa-chevron-right fa-sm pl-1"></i>

                    </a>
                    <a v-else class="btn page-link"
                        v-on:click="changePage(link.url)">
                        <i v-if="link.label === 'Prev'" class="fas fa-chevron-left fa-sm pr-1"></i>
                        {{link.label}}
                        <i v-if="link.label === 'Next'" class="fas fa-chevron-right fa-sm pl-1"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import BidToConfirm from './BidToConfirm.vue'
    export default {
        components: { BidToConfirm },
        props: {
            bidsRoute: {
                required: true
            },
            stripePublicKey: {
                required: true
            }
        },
        data() {
            return {
                bids: {},
                currentRoute: null,
                links: null,
                currentPage: null
            };
        },
        created: function () {
            if (new URL(window.location.href).searchParams.get("page")) {
                let tmp = new URL(this.auctionsRoute)
                tmp.searchParams.set("page", new URL(window.location.href).searchParams.get("page"));
                this.currentRoute = tmp.href;
            } else
                this.currentRoute = this.bidsRoute;
        },
        methods: {
            bidConfirmed(id) {
                this.bids = this.bids.filter(function (ele) {
                    return ele.id != id;
                });
            },
            changePage(url) {
                this.currentRoute = url;
                this.Url.searchParams.set('page', new URL(url).searchParams.get("page"));
                window.history.pushState(null, null, this.Url);
            },
        },
        watch: {
            currentRoute: function () {
                axios.get(this.currentRoute).then(res => {
                    this.bids = res.data.bids.data;
                    this.links = res.data.bids.links;
                    this.currentPage = res.data.bids.current_page;
                });
            }
        },
        computed: {
            Url: function () {
                return new URL(window.location.href);
            }
        }
    };

</script>
