<template>
    <button
        type="button"
        @click="open"
        class="col-11 col-lg-5 btn btn-primary bg-light border-dark text-muted d-inline-flex py-2 my-2 border-1 font-weight-bolder w-100 justify-content-center align-items-center rounded"
    >
        <img v-bind:src="'https://i.imgur.com/txo5Z2m.png'" class="mr-2" style="width: 1.5rem;" />
        <span class="">{{ text }}</span>
    </button>
</template>

<script>
    export default {
        props: {
            href: {
                required: true,
                type: String
            },
            text: {
                required: true
            }
        },
        methods:{
            open(){
                window.location = this.href
            }
        }
    };

</script>

