<template>
    <div class="col-12 py-2 mr-0 d-none d-lg-flex flex-column flex-md-row justify-content-between sticky-top auction-header mb-lg-2 px-3 border">
        <div class="d-flex p-0 m-0 align-items-center flex-grow-1">
            <div class="d-none d-lg-block timer-desktop mr-3">
                <h5 class="p-0 m-0 d-flex align-items-center justify-content-center">
                    <timer
                        :auction-end-props="$store.state.time"
                        :auction-status-props="status"
                        @auction-ended="auctionEnded"
                    />
                </h5>
                <h6 class="font-weight-normal text-uppercase"> {{ __('auction.time_left') }}</h6>
            </div>
            <div class="mr-3">
                <h5 class="font-weight-bold p-0 m-0 text-uppercase text-center">{{ auctionEnd }}</h5>
                <h6 class="text-center font-weight-normal text-uppercase">{{ __('auction.auction_ends') }}</h6>
            </div>
            <div class="d-none d-lg-block ml-auto mr-3">
                <h5 class="font-weight-bold p-0 m-0 text-center">{{ numberOfBids }}</h5>
                <h6 class="p-0 m-0 text-uppercase">{{ __('auction.bids') }}</h6>
            </div>
            <div class="d-none d-lg-block">
                <h5 class="font-weight-bold p-0 m-0 text-center">{{ auctionViews }}</h5>
                <h6 class="p-0 m-0 text-uppercase">{{ __('auction.views') }}</h6>
            </div>
        </div>
        <div class="auction-right-side pl-3">
            <div v-if="canBidProps && isLogged && isOngoing" class="d-flex p-0 m-0 align-items-center justify-content-end pl-3 h-100">
                <form @submit.prevent="" class="form-inline d-flex justify-content-end w-100 h-100">
                    <a class="btn bg-orange text-white letter-0 border-0 py-1 text-uppercase button-fw w-100 h-100 d-flex align-items-center justify-content-center"
                        data-toggle="modal" data-target="#ModalBid">
                        {{ __('auction.place_bid') }}
                    </a>
                </form>
            </div>
            <div v-else-if="biddableProps && isLogged && isOngoing" class="form-inline pr-0 d-flex justify-content-end h-100 pl-3">
                <button class=" btn bg-orange text-white letter-0 border-0 py-1 text-uppercase button-fw h-100 w-100" v-on:click="changePage(routeBillingDetails)">
                    {{ __('auction.place_bid') }}
                </button>
            </div>
            <div v-if="(!canBid) && (!biddableProps) && isOngoing" class="form-inline pr-0 d-flex justify-content-end h-100 pl-3">
                <button class=" btn bg-orange text-white letter-0 border-0 py-1 text-uppercase button-fw w-100 h-100" data-toggle="modal" data-target="#ModalLogin">
                    {{ __('auction.place_bid') }}
                </button>
            </div>
            <div v-if="this.status == 'published'" class="form-inline pr-0 d-flex justify-content-end pl-3 h-100">
                <div class=" btn bg-black text-white letter-0 border-0 py-1 text-uppercase cursor-default button-fw h-100 w-100 d-flex align-items-center justify-content-center">
                    {{ __('auction.coming_auction') }}
                </div>
            </div>
            <div v-if="this.status == 'sold'" class="form-inline pr-0 d-flex align-items-center justify-content-end pl-3 h-100">
                <div class=" btn bg-red text-white letter-0 border-0 py-1 text-uppercase button-fw pointer-auto cursor-default h-100 w-100 d-flex align-items-center justify-content-center">
                    {{ __('auction.sold') }}
                </div>
            </div>
            <div v-if="this.status == 'ended'" class="form-inline pr-0 d-flex align-items-center justify-content-end pl-3 h-100">
                <div class=" btn bg-green text-white letter-0 border-0 py-1 text-uppercase button-fw pointer-auto cursor-default h-100 w-100 d-flex align-items-center justify-content-center">
                    {{ __('auction.ended') }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import componentBid from './componentBid.vue';
import Timer from './Timer.vue';
export default {
    components: { componentBid, Timer },
    props: {
        auctionViews: {
            required: true
        },
        channel: {
            type: String,
            required: true
        },
        route: {
            type: String,
            required: false
        },
        routeBillingDetails: {
            type: String,
            required: false
        },
        biddableProps: {
            required: false
        },
        canBidProps: {
            required: false
        },
        numberOfBidsProps: {
            required: true
        },
        auctionEndProps: {
            required: true
        },
        auctionStatusProps: {
            required: false
        },
        auctionCurrency: {
            type: String,
            required: true
        },
        favProps: {
            required: false
        },
        auctionFavRoute: {
            required: false
        },
        shareRoute: {
            type: String,
            required: true
        },
        routeLogin: {
            type: String,
            required: true
        },
        nick: {
            required: false
        },
        userRoute: {
            required: false
        },
        status: {
            required: true
        }
    },
    created: function () {
        this.numberOfBids = this.numberOfBidsProps -1;
        this.$store.dispatch('changeTime', JSON.parse(this.auctionEndProps));
        this.fav = this.favProps;
        this.biddable = this.biddableProps;
        this.canBid = this.canBidProps;
        this.$store.dispatch('changeIsOngoing', this.status == "ongoing");
    },
    data() {
        return {
            currentBid: null,
            numberOfBids: 0,
            fav: null,
            biddable: null,
            canBid: null,
            affilate: '../images/affilate.svg'
        }
    },
    methods: {
        addFav() {
            axios.post(this.auctionFavRoute).then(res => {
                this.fav = true;
            });
        },
        deleteFav() {
            axios.post(this.auctionFavRoute, {
                _method: 'DELETE'
            }).then(res => {
                this.fav = false;
            });
        },
        auctionEnded() {
            this.$store.dispatch('changeIsOngoing', false);
            this.canBid = false;
            this.biddable = false;
        },
        listenPriceChange() {
            Echo.channel(this.channel)
                .listen('AuctionPriceRaised', (data) => {
                this.$store.dispatch('changePrice', data.new_price);
                this.$store.dispatch('changeWinner', data.winner);
                this.$store.dispatch('changeWinnerRoute', data.winner_route);
                this.$store.dispatch('changeTime', data.new_time);
            })
        },
        changePage(route) {
            location.href = route;
        }
    },
    mounted() {
        this.listenPriceChange()
    },
    computed: {
        isLogged: function() {
            return this.$store.state.isLogged;
        },
        auctionEnd: function() {
            return new Date(this.$store.state.time).toLocaleString('en-GB');
        },
        isOngoing: function() {
            return this.$store.state.isOngoing;
        },
        auctionPrice: function() {
            return this.$store.state.price;
        }
    },
    watch: {
        auctionPrice: {
            handler(value) {
                this.numberOfBids++;
            },
        }
    }
}

</script>
