<template>
    <div class="col-12 col-lg-7 bid-div px-0 p-lg-0 m-0 mb-4 mb-lg-0 d-flex flex-column justify-content-center">
        <div class="d-flex">
            <div v-if="isLogged"  class="d-flex col-4 text-center justify-content-center">
                <img :src="carImgOrange" height="50px" width="50px" v-if="notifySimilarModelWatch == 1" class="pointer link-icon" v-on:click="saveSearch">
                <img :src="carImg" height="50px" width="50px" v-else class="link-icon pointer" data-toggle="modal"  v-on:click="saveSearch">
            </div>
            <div v-else class="d-flex col-4 text-center justify-content-center">
                <img :src="carImg" height="50px" width="50px"  class="link-icon pointer" data-toggle="modal" data-target="#ModalLogin">
            </div>
            <div class="d-flex col-4 text-center justify-content-center">
                <i class="far fa-question-circle pointer text-dark fa-3x link-icon" v-on:click="changePage(buyingRoute)"></i>
            </div>
            <div class="d-flex col-4 text-center justify-content-center">
                <i v-if="isLogged && fav" class="fas fa-star fa-3x text-orange deleteFav pointer" v-on:click="deleteFav"></i>
                <img v-else-if="isLogged" :src="star" class="favourite-icon addFav pointer link-icon" v-on:click="addFav">
                <img v-else :src="star" class="favourite-icon addFav pointer link-icon" data-toggle="modal" data-target="#ModalLogin">
            </div>
        </div>
        <div class="d-flex">
            <div v-if="isLogged" class="d-flex flex-column col-4 text-center span-info">
                <span class="pt-2">{{ __('auction.similiar_model') }}</span>
                <span class="font-weight-bolder pointer text-black link-icon" v-on:click="saveSearch">{{ __('auction.notify_me') }}</span>
            </div>
            <div v-else class="d-flex flex-column col-4 text-center span-info" data-toggle="modal" data-target="#ModalLogin">
                <span class="pt-2">{{ __('auction.similiar_model') }}</span>
                <span class="font-weight-bolder text-black pointer link-icon">{{ __('auction.notify_me') }}</span>
            </div>
            <div class="d-flex flex-column col-4 text-center span-info">
                <span class="pt-2">{{ __('auction.how_buying') }}</span>
            </div>
            <div v-if="isLogged" class="d-flex flex-column col-4 text-center span-info" v-on:click="addFav">
                <span class="pt-2">{{ __('auction.add_to_favorites') }}</span>
            </div>
            <div v-else class="d-flex flex-column col-4 text-center span-info" data-toggle="modal" data-target="#ModalLogin">
                <span class="pt-2">{{ __('auction.add_to_favorites') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        favProps: {
            required: true
        },
        auctionFavRoute: {
            required: true
        },
        notificationRoute: {
            required: true
        },
        auctionParam: {
            required: true
        },
        buyingRoute: {
            required: true
        },
        auctionId: {
            required: true
        },
        userId: {
            required: false
        },
        notifySimilarModel: {
            required: false
        }
    },
    created: function () {
        this.$store.dispatch('changeFav', this.favProps);
        let auction = JSON.parse(this.auctionParam);
        this.params = {
            'auction_id': this.auctionId,
            'model': auction.model,
            'make': auction.make,
            'body_style': auction.body_style,
            'user_id': this.userId,
        };
    },
    data() {
        return {
            params: {},
            finished: false,
            carImg: '/images/car-border.svg',
            carImgOrange: '/images/car-border-orange.svg',
            star: '/images/auction_star.svg',
            notifySimilarModelWatch: this.notifySimilarModel,
        };
    },
    methods: {
        addFav() {
            if(this.isLogged)
                axios.post(this.auctionFavRoute).then(res => {
                    this.$store.dispatch('changeFav', true);
                });
        },
        deleteFav() {
            if(this.isLogged)
                axios.post(this.auctionFavRoute, {
                    _method: 'DELETE'
                }).then(res => {
                    this.$store.dispatch('changeFav', false);
                });
        },
        saveSearch() {
            if(this.isLogged) {
                axios.post(this.notificationRoute, this.params)
                    .then(response => {
                        if (response.data.type === 'add') {
                            Vue.$toast.open({
                                message: this.__('auction.filters.search_saved'),
                                type: 'success',
                            });
                            this.notifySimilarModelWatch = 1

                        } else {
                            Vue.$toast.open({
                                message: this.__('auction.filters.search_deleted'),
                                type: 'warning',
                            });

                            this.notifySimilarModelWatch = 0
                        }
                    })
                    .catch(function(error) {
                        if(error.response.data.errors.search_query)
                            Vue.$toast.open({
                                message: error.response.data.errors.search_query[0],
                                type: 'error',
                            });
                    });
                this.notifyButton = false;
            }
        },
        changePage(route) {
            location.href = route;
        }
    },
    computed: {
        isLogged: function () {
            return this.$store.state.isLogged;
        },
        fav: function () {
            return this.$store.state.fav;
        },
    },
    watch: {
        fav: {
            handler(value) {
                if(!this.finished) {
                    this.finished = !this.finished;
                    return;
                }
                if(this.fav)
                    Vue.$toast.open({
                        message: this.__('auction.added_to_favorites'),
                        type: 'success',
                    });
                else
                    Vue.$toast.open({
                        message: this.__('auction.removed_to_favorites'),
                        type: 'warning',
                    });
            }
        },
        notifySimilarModelWatch: {
            handler: function(newVal, oldVal) {
            },
        },

    }
};

</script>
