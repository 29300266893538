<template>
    <div class="bg-gray border px-2 py-2 sticky-top d-block d-sm-flex justify-content-between align-items-center d-lg-none auction-mobile">
        <div class="d-flex align-items-center">
            <div class="d-flex flex-column mr-1 mr-md-4 align-items-center">
                <span class="font-bold">
                <timer
                        :auction-end-props="$store.state.time"
                        :auction-status-props="status"
                        :mobile-auction="true"
                    />
                </span>
                <small class="text-muted font-weight-normal text-uppercase">{{auctionEnd}}</small>
            </div>
            <div class="d-flex flex-column mr-1 mr-md-3 align-items-center">
                <span class="font-bold p-0 m-0 text-center d-flex align-items-center text-sm"><money :currentBidProps="currentBid" :currency="auctionCurrency"></money></span>
                <small class="text-muted text-uppercase">{{ __('auction.highest_bid') }}</small>
            </div>

            <div class="d-flex flex-column mr-1 mr-md-3 align-items-center">
                <span class="font-bold text-sm">
                {{numberOfBids}}
                </span>
                <small class="text-muted font-weight-normal text-uppercase">{{ __('auction.bids') }}</small>
            </div>

            <div class="d-flex flex-column align-items-center">
                <span class="font-bold text-sm">
                {{auctionViews}}
                </span>
                <small class="text-muted font-weight-normal text-uppercase">{{ __('auction.views') }}</small>
            </div>
        </div>

        <div class="d-flex align-items-center mt-2 mt-md-0">
            <div v-if="canBidProps && isLogged && isOngoing"
                class="d-flex align-items-center w-100">
                    <button class="btn bg-orange border-0 w-100
                        py-2 px-4 text-uppercase button-fw"
                        data-toggle="modal" data-target="#ModalBid">
                        <span class="text-white text-uppercase">
                            {{ __('auction.place_bid') }}</span>
                    </button>
            </div>
            <div v-else-if="biddableProps && isLogged && isOngoing" class="d-flex align-items-center w-100">
                <button class="btn bg-orange border-0 w-100
                        py-2 px-4  text-white text-uppercase button-fw" v-on:click="changePage(routeBillingDetails)">
                    <span class="text-white text-uppercase">
                        {{ __('auction.place_bid') }}
                    </span>
                </button>
            </div>
            <div v-if="(!canBid) && (!biddableProps) && isOngoing" class="d-flex align-items-center w-100">
                <button class="btn bg-orange border-0 w-100
                        py-2 px-4  text-white text-uppercase button-fw" data-toggle="modal" data-target="#ModalLogin">
                    <span class="text-white text-uppercase">
                        {{ __('auction.place_bid') }}
                    </span>
                </button>
            </div>
            <div v-if="this.status == 'published'" class="d-flex align-items-center">
                <button class="btn bg-orange border-0 py-2 px-4  text-white text-uppercase button-fw" disabled>
                    {{ __('auction.coming_auction') }}
                </button>
            </div>
            <div v-if="!isOngoing && numberOfBids > 0" class="d-flex align-items-center">
                <span class="btn bg-red border-0 py-2 px-4  text-white text-uppercase button-fw cursor-default">
                    {{ __('auction.sold') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Timer from './Timer.vue';
import Money from './Money.vue';
export default {
    components: {
        Timer,
        Money
    },
    props: {
        channel: {
            type: String,
            required: true
        },
        route: {
            type: String,
            required: false
        },
        routeBillingDetails: {
            type: String,
            required: false
        },
        biddableProps: {
            required: false
        },
        canBidProps: {
            required: false
        },
        numberOfBidsProps: {
            required: true
        },
        auctionCurrency: {
            type: String,
            required: true
        },
        routeLogin: {
            type: String,
            required: true
        },
        nick: {
            required: false
        },
        userRoute: {
            required: false
        },
        currentBidProps: {
            required: false
        },
        auctionCurrency: {
            required: false
        },
        winnerNick: {
            required: false
        },
        winnerRoute: {
            required: false
        },
        status: {
            required: true
        },
        auctionViews: {
            required: true
        }
    },
    created: function () {
        if(this.winnerNick)
            this.$store.dispatch('changeWinner', this.winnerNick);
        if(this.winnerRoute)
            this.$store.dispatch('changeWinnerRoute', this.winnerRoute);
        this.$store.dispatch('changePrice', this.currentBidProps);
        this.numberOfBids = this.numberOfBidsProps - 1;
        this.fav = this.favProps;
        this.biddable = this.biddableProps;
        this.canBid = this.canBidProps;
    },
    data() {
        return {
            numberOfBids: 0,
            fav: null,
            biddable: null,
            canBid: null,
            affilate: '../images/affilate.svg',
            price: 0,
        }
    },
    methods: {
        addFav() {
            axios.post(this.auctionFavRoute).then(res => {
                this.fav = true;
            });
        },
        deleteFav() {
            axios.post(this.auctionFavRoute, {
                _method: 'DELETE'
            }).then(res => {
                this.fav = false;
            });
        },
        listenPriceChange() {
            Echo.channel(this.channel)
                .listen('AuctionPriceRaised', (data) => {
                this.$store.dispatch('changePrice', data.new_price);
                this.$store.dispatch('changeWinner', data.winner);
                this.$store.dispatch('changeWinnerRoute', data.winner_route);
                this.$store.dispatch('changeTime', data.new_time);
            })
        },
        changePage(route) {
            location.href = route;
        },
        numberWithSpaces(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            if(parts.length > 1)
                if(parts[1].length == 1)
                    parts[1]+='0';
            return parts.join(".");
        }
    },
    mounted() {
        this.listenPriceChange()
    },
    computed: {
        isLogged: function() {
            return this.$store.state.isLogged;
        },
        isOngoing: function() {
            return this.$store.state.isOngoing;
        },
        auctionEnd: function() {
            return new Date(this.$store.state.time).toLocaleString('en-US');
        },
        auctionPrice: function() {
            return this.$store.state.price;
        },
        currentBid: function() {
            return this.numberWithSpaces(this.$store.state.price);
        },
    },
    watch: {
        auctionPrice: {
            handler(value) {
                this.numberOfBids++;
            },
        }
    }
}

</script>
