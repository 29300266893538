<template>
</template>

<script>
export default {
    props: {
        isLogged: {
            required: true
        }
    },
    created: function () {
        this.$store.dispatch('changeIsLogged', this.isLogged);
    }
};
</script>