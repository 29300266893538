<template>
    <tr class="d-flex flex-column flex-md-row justify-content-between car-div-color">
        <td class="d-flex flex-column  flex-md-row pb-0 py-md-3 border-0 align-items-center flex-grow-1">
            <!-- photo -->
            <div>
                <a :href="bid.auction_route">
                    <img :src="bid.image_url" alt="photo" class="rounded img-cover" width="150px" height="100px">
                </a>
            </div>
            <!-- informations -->
            <div class="d-flex flex-column align-items-center align-items-md-start">
                <div class="px-3 pt-2 pt-md-0 d-flex flex-wrap align-items-center justify-content-center text-center">
                    <a class="text-dark" :href="bid.auction_route">
                        <h3 class="font-weight-bolder text-uppercase mb-0">{{bid.name}}</h3>
                    </a>
                    <button class="far fa-lg text-danger pl-1 pl-md-3 pb-1" v-on:click="confirmBid">confirm</button>
                </div>
                <div class="px-3 d-flex flex-row flex-md-column align-items-start justify-content-center">
                    <span class="text-muted pr-0 pr-md-2">{{bid.country}}, {{bid.city}}</span>
                </div>
            </div>
        </td>
        <!-- time/price -->
        <td class="d-flex border-0 justify-content-center flex-shrink-0">
            <div class="d-flex flex-column align-items-center align-items-md-end justify-content-center">
                <div class="d-flex justify-content-end">
                    <i class="far fa-clock pr-2"></i>
                    <span class="font-weight-bolder">{{auctionEnd}}</span>
                </div>
                <div class="d-flex justify-content-end  px-2 pl-2 pr-md-0 py-md-2">
                    <h5 class="text-orange font-weight-bolder mb-0"><money :currentBidProps="bid.current_bid" :currency="bid.currency"></money></h5>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import Money from './Money.vue';
export default {
    components: {
        Money 
    },
    props: {
        bid: {
            required: true
        },
        stripePublicKey: {
            required: true,
        },
    },
    data() {
        return{
            price: 0,
        }
    },
    methods: {
        confirmBid() {
            Stripe(this.stripePublicKey)
                .confirmCardPayment(this.bid.client_secret)
                    .then(result => {
                        if(!result.error) {
                            axios.post(this.bid.confirm_route).then(response => {
                                this.$emit('bid-confirmed', this.bid.id);
                                Vue.$toast.open({
                                    message: 'Bid confirmed!',
                                    type: 'success',
                                }); 
                            })
                        } else {
                            axios.post(this.bid.refresh_route).then(response => {
                                Vue.$toast.open({
                                    message: 'Try again!',
                                    type: 'error',
                                }); 
                            })
                        }
                    })
        },
    },
    computed: {
        auctionEnd: function() {
            return new Date(this.bid.end).toLocaleString('en-US');
        },
    },
}
</script>
