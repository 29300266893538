<template>
    <div class="d-flex stripe flex-column">
        <!-- Stripe Elements Placeholder -->
        <div class="d-flex justify-content-between">
            <h5 class="p-0">{{ __('auction.insert_card_number') }}</h5>
            <h5 class="p-0 text-right">{{ __('auction.insert_expiration_date') }} & CVC</h5>
        </div>
        <div class="form-group">
            <div ref="card"></div>
        </div>
        <div class="form-group">
            <button id="card-button" class="btn bg-orange border-0 button-fw text-uppercase text-white py-1 px-2" v-on:click="send">
                {{ __('auction.update_payment_method') }}
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            stripePublicKey: {
                required: true,
            },
            clientSecret: {
                required: true,
            },
            route: {
                required: true,
            },
            locale: {
                required: true
            }
        },
        mounted() {
            this.$loadScript("https://js.stripe.com/v3/")
                .then(() => {
                    this.setUp();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        data() {
            return {
                card: null,
                stripe: null
            }
        },
        methods: {
            setUp() {
                this.stripe = Stripe(this.stripePublicKey, {
                    locale: this.locale
                });

                const elements = this.stripe.elements();
                this.card = elements.create('card', {
                    style: {
                        base: {
                            lineHeight: '2rem',
                        },
                        invalid: {
                            iconColor: 'red',
                            color: 'red',
                        },
                    }
                });

                this.card.mount(this.$refs.card);
            },
            async send() {
                const {
                    setupIntent,
                    error
                } = await this.stripe.confirmCardSetup(
                    this.clientSecret, {
                        payment_method: {
                            card: this.card,
                        }
                    }
                );

                if (error) {
                    Vue.$toast.open({
                        message: this.__('billing.general_error'),
                        type: 'error',
                    });
                    setTimeout(() => {
                        location.reload();
                    }, 5000);
                } else {
                    const data = {
                        id: setupIntent.payment_method
                    };
                    axios.post(this.route, data)
                        .then(response => {
                            Vue.$toast.open({
                                message: this.__('billing.payment_change_successful'),
                                type: 'success',
                            });
                            setTimeout(() => {
                                window.history.back();
                            }, 5000);
                        })
                        .catch(response => {
                            Vue.$toast.open({
                                message: this.__('billing.general_error'),
                                type: 'error',
                            });
                            setTimeout(() => {
                                location.reload();
                            }, 5000);
                        });
                }
            }
        }
    }

</script>
