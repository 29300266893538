/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import axios from "axios";
import AvatarUpload from "./components/AvatarUpload"
import ProfileDetails from "./components/ProfileDetails"
import comments from './components/Comments'
import Conversations from './components/Conversations'
import componentAuction from './components/componentAuction'
import componentAuctionMobile from './components/componentAuctionMobile'
import componentPrice from './components/componentPrice'
import componentBid from './components/componentBid'
import componentStripe from './components/componentStripe'
import moment from 'moment';
import 'moment/locale/pl';
import bootbox from 'bootbox';
import IsLogged from './components/IsLogged'
import MobileSearch from './components/MobileSearch'
import MobileCloseSearch from './components/MobileCloseSearch'
import Auction from './components/Auction'
import Auctions from './components/Auctions'
import AddPhotos from './components/AddPhotos'
import UploadBlock from './components/UploadBlock'
import VueUploader from "./components/VueUploader"
import ItemBlock from "./components/ItemBlock"
import ItemUploadingBlock from "./components/ItemUploadingBlock"
import AuctionsAffiliation from './components/AuctionsAffiliation'
import Favourite from './components/Favourite'
import Favourites from './components/Favourites'
import MyAuctions from './components/MyAuctions'
import MyBids from './components/MyBids'
import MyMaxBids from './components/MyMaxBids'
import Affiliations from './components/Affiliations'
import componentAffiliations from './components/componentAffiliations'
import BidsToConfirm from './components/BidsToConfirm'
import Search from './components/Search'
import Searches from './components/Searches'
import Bids from './components/Bids'
import FavouriteAndShare from './components/FavouriteAndShare'
import FavouriteOnPhoto from './components/FavouriteOnPhoto'
import componentSearch from './components/componentSearch'
import componentFilters from './components/componentFilters'
import componentFiltersAuctionType from './components/componentFiltersAuctionType'
import AffiliateLink from './components/AffiliateLink'
import componentNotifyAndFavourite from './components/componentNotifyAndFavourite'
import MobileBurger from './components/MobileBurger'
import TimeBar from './components/TimeBar'
import Vuex from 'vuex'
import Vue from "vue";
import LoadScript from 'vue-plugin-load-script';
import dragscrollNext from 'vue-dragscroll';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import translationText from './plugins/translationText';
import translation from './plugins/translation';
import Gallery from './components/Gallery';
import Galleria from 'primevue/galleria';
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import componentDivider from './components/componentDivider';
import AuthButtonGoogle from './components/AuthButtonGoogle';
import AuthButtonFacebook from './components/AuthButtonFacebook';
import AuthButtonApple from './components/AuthButtonApple';
import Tooltip from 'primevue/tooltip';
import Carousel from 'primevue/carousel';
import componentCarousel from './components/componentCarousel';
import componentCarouselSell from './components/componentCarouselSell';
import CarouselMain from './components/CarouselMain';
import CarouselSlide from './components/CarouselSlide';
import Rating from 'primevue/rating';
import ConfirmDialog from 'primevue/confirmdialog';
import buttonToastDelete from './components/buttonToastDelete';
import ConfirmationService from 'primevue/confirmationservice';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';                           //icons

window.Vue = require('vue').default;
window.moment = moment;
window.bootbox = bootbox;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.use(LoadScript);
Vue.use(dragscrollNext)
Vue.use(VueToast, {
    position: 'bottom-right',
    duration: 5000,
});
Vue.component('componentAuction', componentAuction)
Vue.component('componentAuctionMobile', componentAuctionMobile)
Vue.component('componentPrice', componentPrice)
Vue.component('componentBid', componentBid)
Vue.component('comments', comments)
Vue.component('Conversations', Conversations)
Vue.component('componentStripe', componentStripe)
Vue.component('UploadImages', AvatarUpload)
Vue.component('ProfileDetails', ProfileDetails)
Vue.component('IsLogged', IsLogged)
Vue.component('MobileSearch', MobileSearch)
Vue.component('MobileCloseSearch', MobileCloseSearch)
Vue.component('Auction', Auction)
Vue.component('Auctions', Auctions)
Vue.component('AddPhotos', AddPhotos)
Vue.component('AuctionsAffiliation', AuctionsAffiliation)
Vue.component('Favourite', Favourite)
Vue.component('Favourites', Favourites)
Vue.component('MyAuctions', MyAuctions)
Vue.component('MyMaxBids', MyMaxBids)
Vue.component('MyBids', MyBids)
Vue.component('Affiliations', Affiliations)
Vue.component('componentAffiliations', componentAffiliations)
Vue.component('BidsToConfirm', BidsToConfirm)
Vue.component('Search', Search)
Vue.component('Searches', Searches)
Vue.component('Bids', Bids)
Vue.component('FavouriteAndShare', FavouriteAndShare)
Vue.component('FavouriteOnPhoto', FavouriteOnPhoto)
Vue.component('componentSearch', componentSearch)
Vue.component('componentFilters', componentFilters)
Vue.component('componentFiltersAuctionType', componentFiltersAuctionType)
Vue.component('AffiliateLink', AffiliateLink)
Vue.component('componentNotifyAndFavourite', componentNotifyAndFavourite)
Vue.component('MobileBurger', MobileBurger)
Vue.component('TimeBar', TimeBar)
Vue.component('Gallery', Gallery)
Vue.component('Galleria', Galleria);
Vue.component('Dialog', Dialog);
Vue.component('Divider', Divider);
Vue.component('componentDivider', componentDivider);
Vue.component('AuthButtonGoogle', AuthButtonGoogle);
Vue.component('AuthButtonFacebook', AuthButtonFacebook);
Vue.component('AuthButtonApple', AuthButtonApple);
Vue.component('Tooltip', Tooltip);
Vue.component('Carousel', Carousel);
Vue.component('componentCarousel', componentCarousel);
Vue.component('Rating', Rating);
Vue.component('ConfirmDialog', ConfirmDialog);
Vue.component('buttonToastDelete', buttonToastDelete);
Vue.component('ItemBlock', ItemBlock)
Vue.component('ItemUploadingBlock',ItemUploadingBlock);
Vue.component("UploadBlock",UploadBlock);
Vue.component("VueUploader",VueUploader);
Vue.component("CarouselMain",CarouselMain);
Vue.component("CarouselSlide",CarouselSlide);
Vue.component("componentCarouselSell",componentCarouselSell);
Vue.use(ConfirmationService);
Vue.use(Vuex);
Vue.use(PrimeVue);

// let currentType = 'SPORT';
// if (document.referrer && document.referrer.includes('favourites')) { // z routa przechodzi na all
//     let params = window.location.search.replace('?', '').split('&');
//     let find = false;
//     for (var i = 0; i < params.length; i++) {
//         let param = params[i].split("=");
//         if (param.includes('type')) {
//             currentType = param[1];
//             find = true;
//             break;
//         }
//     }
//     if (!find)
//         currentType = null;
// }

const store = new Vuex.Store({
    state: {
        search_query: null,
        loading: true,
        page: null,
        params: {
            make: null,
            model: null,
            mileage: null,
            transmission: null,
            seller_type: null,
            engine_type: null,
            engine_capacity: null,
            engine_cylinders: null,
            body_styles: null,
            voivodeship_types: null,
            control: null,
            drive: null,
            colour: null,
            accept_crypto: null,
            damage_type: null,
            type: null,
            status: null,
            sort_by: 'end',
            mileage_from: null,
            mileage_to:null,
            capacity_from: null,
            capacity_to: null,
            bid_from: null,
            bid_to: null,
            year_from: null,
            year_to: null,
            minimal_price: null,
            country_type: null,
            invoice_type: null,
            hot_deal: null,
        },
        price: null,
        newPrice: null,
        time: null,
        isLogged: false,
        isOngoing: false,
        winner: null,
        winnerRoute: null,
        fav: null,
        mobileSearch: false,
        stored_translations: {}
    },
    mutations: {
        CLEAR_FILTERS(state) {
            state.params = {
                make: null,
                model: null,
                mileage: null,
                transmission: null,
                seller_type: null,
                engine_type: null,
                engine_capacity: null,
                engine_cylinders: null,
                body_styles: null,
                voivodeship_types: null,
                control: null,
                drive: null,
                colour: null,
                accept_crypto: null,
                damage_type: null,
                mileage_from: null,
                mileage_to:null,
                capacity_from: null,
                capacity_to: null,
                bid_from: null,
                bid_to: null,
                year_from: null,
                year_to: null,
                minimal_price: null,
                country_type: null,
                type: null,
                status: null,
                invoice_type: null,
                sort_by: 'end',
                hot_deal: null,
            }
        },
        CHANGE_SEARCH_QUERY(state, searched) {
            state.search_query = searched;
        },
        CHANGE_LOADING_STATUS(state, status) {
            state.loading = status;
        },
        CHANGE_PARAM(state, payload) {
            Object.assign(state.params, { [payload.paramName]: payload.param });
        },
        CHANGE_PAGE(state, page) {
            state.page = page;
        },
        CHANGE_PRICE(state, price) {
            state.price = price;
        },
        CHANGE_TIME(state, time) {
            state.time = time;
        },
        CHANGE_ISLOGGED(state, isLogged) {
            state.isLogged = isLogged;
        },
        CHANGE_ISONGOING(state, isOngoing) {
            state.isOngoing = isOngoing;
        },
        CHANGE_WINNER(state, winner) {
            state.winner = winner;
        },
        CHANGE_WINNERROUTE(state, winnerRoute) {
            state.winnerRoute = winnerRoute;
        },
        CHANGE_FAV(state, fav) {
            state.fav = fav;
        },
        CHANGE_MOBILESEARCH(state, mobileSearch) {
            state.mobileSearch = mobileSearch;
        },
        CHANGE_NEWPRICE(state, newPrice) {
            state.newPrice = newPrice;
        },
        ADD_TRANSLATIONS(state, words) {
            state.stored_translations = Object.assign(
                {},
                state.stored_translations,
                words
            );
        },
    },
    actions: {
        search({ commit }, searched) {
            commit('CHANGE_LOADING_STATUS', true);
            commit('CHANGE_SEARCH_QUERY', searched);
        },
        changeLoadingStatus({ commit }, status) {
            commit('CHANGE_LOADING_STATUS', status);
        },
        changeParams({ commit }, payload) {
            commit('CHANGE_PARAM', payload);
        },
        changePage({ commit }, page) {
            commit('CHANGE_PAGE', page);
        },
        changePrice({ commit }, price) {
            commit('CHANGE_PRICE', price);
        },
        changeTime({ commit }, time) {
            commit('CHANGE_TIME', time);
        },
        changeIsLogged({ commit }, isLogged) {
            commit('CHANGE_ISLOGGED', isLogged);
        },
        changeIsOngoing({ commit }, isOngoing) {
            commit('CHANGE_ISONGOING', isOngoing);
        },
        changeWinner({ commit }, winner) {
            commit('CHANGE_WINNER', winner);
        },
        changeWinnerRoute({ commit }, winnerRoute) {
            commit('CHANGE_WINNERROUTE', winnerRoute);
        },
        changeFav({ commit }, fav) {
            commit('CHANGE_FAV', fav);
        },
        changeMobileSearch({ commit }, mobileSearch) {
            commit('CHANGE_MOBILESEARCH', mobileSearch);
        },
        changeNewPrice({ commit }, newPrice) {
            commit('CHANGE_NEWPRICE', newPrice);
        },
        fetchTranslations(store, text) {
            return axios
                .post("/translations/translate-array", { keys: text })
                .then(response => {
                    store.commit("ADD_TRANSLATIONS", response.data.translations);
                });
        },
    },
    modules: {},
    getters: {
        getTranslation: (state) => (word) => (
            state.stored_translations.hasOwnProperty(word)
                ? state.stored_translations[word]
                : word
        )
    }
})

Vue.use(translation, { store });

$.holdReady(true);
document.addEventListener("DOMContentLoaded", async () => {
    await store.dispatch("fetchTranslations", translationText);
    new Vue({
        el: "#app",
        mounted: function () {
            $.holdReady(false);
        },
        store,
    });
});
