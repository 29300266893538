<template>
    <div>
        <div class="d-flex flex-column flex-md-row text-center text-md-left">
            <!-- nick -->
            <div class="col-12 col-md-6 pb-3">
                <label for="nick" class="font-weight-bolder">{{ __('user_profile.nick') }}</label>
                <input-wrapper pattern="[a-zA-Z]+" type="text" id="nick" maxlength="30"
                    :editable="isEditable" v-model="form.nick"/>
                <span v-if="errors.nick" class="feedback" role="alert">
                    <strong class="text-danger" v-for="error in errors.nick" :key="error">{{ error }}</strong>
                </span>
            </div>
            <!-- phone_number -->
            <div class="col-12 col-md-6 pb-3">
                <label for="phone_number" class="font-weight-bolder">{{ __('user_profile.phone_number') }}</label>
                <input-wrapper id="phone_number" maxlength="70" :editable="isEditable"
                    v-model="form.phone_number" />
                <span v-if="errors.phone_number" class="feedback" role="alert">
                    <strong class="text-danger"  v-for="error in errors.phone_number" :key="error">{{ error }}</strong>
                </span>
            </div>
        </div>
        <!-- name/surname -->
        <div class="d-flex flex-column flex-md-row text-center text-md-left">
            <!-- name -->
            <div class="col-12 col-md-6 pb-3">
                <label for="name" class="font-weight-bolder">{{ __('user_profile.name') }}</label>
                <input-wrapper id="name" maxlength="30"
                    :editable="isEditable" v-model="form.name"/>
                <span v-if="errors.name" class="feedback" role="alert">
                    <strong class="text-danger" v-for="error in errors.name" :key="error">{{ error }}</strong>
                </span>
            </div>
            <!-- surname -->
            <div class="col-12 col-md-6 pb-3">
                <label for="surname" class="font-weight-bolder">{{ __('user_profile.surname') }}</label>
                <input-wrapper id="surname" maxlength="70" :editable="isEditable"
                    v-model="form.surname" />
                <span v-if="errors.surname" class="feedback" role="alert">
                    <strong class="text-danger" v-for="error in errors.surname" :key="error">{{ error }}</strong>
                </span>
            </div>
        </div>
        <!-- car preferences/localisation -->
        <div class="d-flex flex-column flex-md-row text-center text-md-left py-0 py-md-4">
            <div class="col-12 col-md-6 pb-3">
                <label for="car-preferences" class="font-weight-bolder">{{ __('user_profile.car_preferences') }}</label>
                <input-wrapper type="text" id="car-preferences" maxlength="50" :editable="isEditable"
                    v-model="form.car_preferences" />
                <span v-if="errors.car_preferences" class="feedback" role="alert">
                    <strong class="text-danger" v-for="error in errors.car_preferences" :key="error">{{ error }}</strong>
                </span>
            </div>
            <!-- localisation -->
            <div class="col-12 col-md-6 pb-3">
                <label for="localisation" class="font-weight-bolder">{{ __('user_profile.localisation') }}</label>
                <input-wrapper type="text" id="localisation" maxlength="70" :editable="isEditable"
                    v-model="form.localisation" />
                <span v-if="errors.localisation" class="feedback" role="alert">
                    <strong class="text-danger" v-for="error in errors.localisation" :key="error">{{ error }}</strong>
                </span>
            </div>
        </div>
        <div class="d-flex flex-column flex-md-row text-center text-md-left py-0 py-md-4">
            <div class="col-12 col-md-6 pb-3">
                <label for="owned-cars" class="font-weight-bolder">{{ __('user_profile.owned_cars') }}</label>
                <input-wrapper type="text" id="owned-cars" maxlength="50" :editable="isEditable"
                    v-model="form.owned_cars" />
            </div>
            <div class="col-12 col-md-6 pb-3">
                <label for="hobbys" class="font-weight-bolder">{{ __('user_profile.hobbies') }}</label>
                <textarea-wrapper type="text" id="hobbys" maxlength="600" :editable="isEditable" v-model="form.hobbys" />
            </div>
        </div>
        <!-- change deatils -->
        <div class="col-12 col-md-6 d-flex flex-row mw-100 align-items-start justify-content-center">
            <button
                class="btn b-g-blue text-white border-0 text-white px-4 py-1 text-uppercase letter-0 button-fw bg-black"
                type="button" @click="edit" v-if="!isEditable">{{ __('user_profile.edit') }}</button>
            <button class="btn border-0 bg-orange py-1 text-white letter-0 button-fw text-uppercase mr-2"
                v-if="isEditable" @click="cancel">{{ __('user_profile.cancel') }}</button>
            <button class="btn border-0 bg-black py-1 text-white button-fw letter-0 text-uppercase"
                v-if="isEditable" @click="submit">{{ __('user_profile.save') }}</button>
        </div>
    </div>
</template>

<script>
    import InputWrapper from './InputWrapper.vue'
    import TextareaWrapper from './TextareaWrapper.vue'
    export default {
        components: {
            InputWrapper,
            TextareaWrapper
        },
        props: {
            initialData: {
                type: [Array, Object],
                require: true,
            },
            updateRoute: {
                require: true,
            },
        },
        data() {
            return {
                isEditable: false,
                form: {
                    nick: '',
                    name: '',
                    surname: '',
                    phone_number: '',
                    localisation: '',
                    car_preferences: '',
                    owned_cars: '',
                    hobbys: '',
                },
                errors: {},
                formCopy: {}
            }
        },
        methods: {
            edit() {
                this.formCopy = {
                    ...this.form
                }
                this.isEditable = true
            },
            submit() {
                axios.patch(this.updateRoute, this.form)
                    .then(res => {
                        this.isEditable = false
                        this.errors = {};
                        Vue.$toast.open({
                            message: this.__('profile.profile_updated'),
                            type: 'success',
                        });
                    })
                    .catch(error => {
                        this.errors = {
                            ...error.response.data.errors
                        };
                        Vue.$toast.open({
                            message: this.__('profile.error'),
                            type: 'error',
                        });
                    });;
            },
            cancel() {
                this.form = {
                    ...this.formCopy
                }
                this.formCopy = {}
                this.errors = {};
                this.isEditable = false
            }
        },
        mounted() {
            this.form = {
                ...this.initialData
            };
        },
    }

</script>

<style lang="scss" scoped>

</style>
