<template>
<transition name="modal">
    <div tabindex="-1" role="dialog" class="modal fade show p-0" style="padding-right: 17px; display: block;">
        <div role="document" class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content border-0">
                <div class="modal-header border-0">
                    <button class="close webkit" v-on:click="close">
                    <i class="fas fa-times text-muted"></i>
                    </button>
                </div>
                <div class="modal-body position-relative text-center p-0 pb-5">
                    <div class="bg-transparent border-0 text-center">
                    <h2 class="title font-weight-bolder pb-3">{{ __('auction.delete') }} {{ type }}</h2>
                    </div>
                    <div>
                    <h5 class="text-muted p-0 m-0">
                        {{ __('auction.delete_confirm_text') }} {{ type }}?
                    </h5>
                    </div>
                    <div class="d-flex justify-content-center">
                    <div
                        class="d-flex col-10 col-md-4 p-0 m-0 justify-content-between pt-4"
                    >
                        <button
                        class="btn bg-red px-5 py-1 text-white button-fw"
                        v-on:click="deleteComponent"
                        >
                        {{ __('auction.yes') }}
                        </button>
                        <button
                        class="btn bg-black px-5 py-1 text-white button-fw"
                        v-on:click="close"
                        >
                        {{ __('auction.no') }}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    props: {
        route: {
        type: String,
        required: true,
        },
        type: {
            required: true
        }
    },
    methods: {
        deleteComponent() {
            axios.post(this.route, {
                    _method: 'DELETE'
                }).then(res => {
                    this.$emit('deleted', res.data.data);
                    this.close();
                });
        },
        show() {
            document.body.classList.add('modal-open');
            document.body.style.paddingRight = "17px";
            var elemDiv = document.createElement('div');
            elemDiv.classList.add('modal-backdrop');
            elemDiv.classList.add('fade');
            elemDiv.classList.add('show');
            document.body.appendChild(elemDiv);
        },
        close() {
            this.$emit('closed');
            document.body.classList.remove('modal-open');
            document.body.style.paddingRight = "0px";
            const elements = document.getElementsByClassName('modal-backdrop');
            while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
            }
        },
    },
    mounted() {
        this.show();
    },
};
</script>
