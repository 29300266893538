<template>
    <span class="text-break">{{currentBid }} {{this.currency}} </span>
</template>

<script>
export default {
    props: {
        currentBidProps: {
            required: true
        },
        currency: {
            required: true
        }
    },
    methods: {
        numberWithSpaces(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (parts.length > 1)
                if (parts[1].length == 1)
                    parts[1] += '0';
            return parts.join(".");
        }
    },
    computed: {
        currentBid: function () {
            return this.numberWithSpaces(this.currentBidProps);
        },
    },
}
</script>
