<template>
    <div v-if="editable">
        <textarea v-bind="$attrs" class="border rounded p-2 w-100" rows="5" :value="value" @input="$emit('input', $event.target.value)">
        </textarea>
    </div>
    <div v-else>
        {{ value }}
    </div>
</template>

<script>
    export default {
        inheritAttrs: false,
        props: {
            editable: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [String, Number]
            }
        },
        computed: {
            valueText() {
                return this.$attrs.value 
            }
        },
    }
</script>