<template>
    <tr
        class="d-flex flex-column flex-md-row pb-3 pb-md-0 my-3 my-md-0 justify-content-center justify-content-md-between car-div-color">
        <td class="d-flex flex-column p-0 p-md-3 flex-md-row border-0 flex-grow-1">
            <div class="d-flex justify-content-center align-items-center flex-shrink-0">
                <a class="image-wrapper" :href="auction.route">
                    <img :src="auction.image_url" alt="photo"
                        class="mw-100 mh-100 img-cover" height="100px" width="150px">
                </a>
            </div>
            <div
                class="px-3 d-flex flex-column align-items-center align-items-md-start">
                <h5
                    class="font-weight-bolder mb-0 pt-2 pt-md-0 text-uppercase text-center text-md-left">
                    <a class="text-dark text-break"
                        :href="auction.route">
                        {{ auction.name }}
                    </a>
                </h5>

                <div class="d-flex align-items-center">
                <img class="auction-country-flag mr-2 border" :src="flagIcon">
                <span class="text-secondary"> {{auction.city}}</span>
            </div>
            </div>
        </td>
        <td  v-if="auction.new_comments"
            class="d-flex justify-content-center py-0 py-md-2 align-items-center border-0">
            <div>
                <h5 class="text-orange font-weight-bolder">{{ __('auction.new_comments') }}</h5>
            </div>
        </td>
        <td v-if="auction.end"
            class="border-0 p-3 d-flex align-items-center justify-content-center flex-shrink-0">
            <div  class="d-flex flex-md-column justify-content-center">
                <div class="d-flex justify-content-center justify-content-lg-end">
                    <i class="far fa-clock pt-1 pr-1"></i>
                    <span class="font-weight-bolder">
                        <timer
                            :auction-end-props="auction.end" :auction-status-props="auction.status"
                        ></timer></span>
                </div>
                <div
                    class="d-flex px-3 px-md-0 py-md-2 justify-content-center justify-content-lg-end flex-shrink-0" style="height: 40px;">
                    <h5 class="text-orange text-center font-weight-bolder mb-0">
                        <money :currentBidProps="auction.current_bid" :currency="auction.currency"></money>
                    </h5>
                </div>
                <div class="d-flex justify-content-center justify-content-lg-end">
                    <span class="font-weight-bolder">
                        {{auction.number_of_bids}} {{ __('auction.bids') }}
                    </span>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import Timer from './Timer.vue';
import Money from './Money.vue';
export default {
    components: {
        Timer,
        Money
    },
    props: {
        auction: {
            required: true
        },
    },
    data() {
        return {
            timeLeft: null,
            notify: null,
        };
    },
    methods: {
        numberWithSpaces(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            if(parts.length > 1)
                if(parts[1].length == 1)
                    parts[1]+='0';
            return parts.join(".");
        },
    },
    computed: {
        currentBid: function() {
            return this.numberWithSpaces(this.auction.current_bid);
        },
        flagIcon() {
            return `images/flags/${this.auction.country.flag}`
        }
    }
};

</script>
