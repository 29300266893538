<template>
  <a
    class="nav-link d-flex"
    :class="{
      'new-messages': conversation.new_messages,
      'car-div-color': !conversation.new_messages,
      active: active,
    }"
    id="v-pills-home-profile"
    data-toggle="pill"
    href="#v-pills-profile"
    role="tab"
    aria-controls="v-pills-profile"
    aria-selected="false"
    v-on:click="conversationSelected"
  >
    <div>
      <img
        :src="conversation.auction.image_url"
        alt="photo"
        class="rounded img-cover"
        width="150px"
        height="75px"
      />
    </div>
    <div class="d-flex flex-column justify-content-between w-100 pl-3">
      <h5 class="p-0 m-0 font-weight-bolder text-break text-uppercase">
        {{ conversation.auction.name }}
      </h5>
      <div v-if="conversation.new_messages" class="text-right">
        <span>{{ __("conversation.new_messages") }}</span>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    conversation: {
      required: true,
    },
    active: {
      required: true,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    conversationSelected() {
      this.conversation.new_messages = false;
      this.$emit("conversation-selected", this.conversation);
    },
  },
};
</script>

<style scoped>
.nav-link {
  border: 2px solid transparent;
}
</style>
