<template>
  <div class="flex-column align-items-start mb-4" v-if="currentStatus">
    <div>
      <h1 class="font-weight-bolder text-center price p-0 m-0">
        <money :currentBidProps="currentBid" :currency="auctionCurrency">
        </money>
      </h1>
    </div>
    <div class="text-center ">
      <h6 class="p-0 m-0">
        <p class="text-uppercase mb-0">{{ __("auction.highest_bid") }}</p>
        <span v-if="currentWinner"  class="text-muted">{{currentWinner}}</span>
      </h6>
    </div>
  </div>
</template>

<script>
import Money from "./Money.vue";
export default {
  components: {
    Money,
  },
  props: {
    currentBidProps: {
      required: false,
    },
    auctionCurrency: {
      required: false,
    },
    nick: {
      required: false,
    },
    route: {
      required: false,
    },
    status: {
      required: true,
    },
  },
  created: function () {
    if (this.nick) this.$store.dispatch("changeWinner", this.nick);
    if (this.route) this.$store.dispatch("changeWinnerRoute", this.route);
    this.$store.dispatch("changePrice", this.currentBidProps);
  },
  computed: {
    currentBid: function () {
      return this.$store.state.price;
    },
    currentWinner: function () {
      return this.$store.state.winner;
    },
    currentRoute: function () {
      return this.$store.state.winnerRoute;
    },
    params: function () {
      return this.$store.state.params;
    },
    currentStatus: function () {
      return this.status == "published" ? false : true;
    },
  },
};
</script>
