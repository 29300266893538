<template>
    <div class="col-12 col-lg-6 col-xl-5 col-2xl-4 pb-5 pb-lg-0 justify-content-center align-self-center">
        <Carousel :value="opinions" :circular="true" :autoplayInterval="3000">
            <template #item="slotProps">
                <div class="p-2 mx-auto max-box">
                    <div class="d-flex">
                        <!-- image - Owner changed his mind, commented because of high chances of this event occuring again -->
                        <!-- <div>
                            <img :src="slotProps.data.car" alt="bidpark" width="95px">
                        </div> -->
                        <!-- title -->
                        <div>
                            <h6 class="m-0 name-clamp-nohover">{{slotProps.data.carName}}</h6>
                            <p class="text-muted m-0" style="font-size: 0.8rem">{{slotProps.data.buyer}}</p>
                            <Rating class="mt-1" v-model="slotProps.data.stars" :readonly="true" :cancel="false" />
                        </div>
                    </div>
                    <!-- description -->
                    <div class="my-2">
                        <span style="font-size: 0.9rem">{{slotProps.data.description}}</span>
                    </div>
                </div>
            </template>
        </Carousel>
    </div>
</template>
<script>

export default {
    data() {
        return {
            opinions:
                [
                    {
                        carName: 'BMW M3',
                        buyer: 'Stanisław Lech',
                        description: 'Samochód był w idealnym stanie. Podczas jazdy czułem się komfortowo. ',
                        car: "/images/opinions/bmw_m3.jpeg",
                        stars: 5,
                    },
                    {
                        carName: 'PORSCHE 911',
                        buyer: 'Bartłomiej Zawodny',
                        description: 'Samochód jest naprawdę szybki i wygodny.',
                        car: "/images/opinions/porsche_911.jpg",
                        stars: 5,
                    },
                    {
                        carName: 'MERCEDES-BENZ CLA 45 AMG',
                        buyer: 'Oskar Rogacki',
                        description: 'Jest szybki, wygodny i przyjemny w prowadzeniu.',
                        car: "/images/opinions/MERCEDES_BENZ_CLA_45_AMG.jpg",
                        stars: 5,
                    },
                    {
                        carName: 'JAGUAR XJ8 4.2 S',
                        buyer: 'Dawid Mondruk',
                        description: 'Byłem bardzo szczęśliwy, gdy znalazłem go na Bidpark.',
                        car: "/images/opinions/JAGUAR_XJ8_4.2_S.jpg",
                        stars: 5,
                    },
                    {
                        carName: 'BENTLEY CONTINENTAL GT W12',
                        buyer: 'Marcin Gądrek',
                        description: 'Silnik jest bardzo mocny,a doświadczenia z jazdy są świetne.',
                        car: "/images/opinions/BENTLEY_CONTINENTAL_GT_W12.jpg",
                        stars: 5,
                    },
                    {
                        carName: 'FERRARI 360 MODENA F1',
                        buyer: 'Filip Drzyga',
                        description: 'Zdecydowanie poleciłbym Bidpark każdemu.',
                        car: "/images/opinions/FERRARI_360_MODENA_F1.jpg",
                        stars: 5,
                    },
                ],
        }
    },
}
</script>

<style lang="scss">
.p-carousel-prev, .p-carousel-next {
    @media (max-width: 599px) {
        display: none;
    }
}

.p-carousel-indicators {
    @media (min-width: 600px){
        display: none;
    }
}
.p-rating .p-rating-icon {
    color: #facc15 !important;
    cursor: default;
}

.p-carousel .p-carousel-content .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 0.8rem;
  height: 0.8rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 1rem;
}

.max-box {
    max-width: 300px;
}

.name-clamp-nohover {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
</style>
