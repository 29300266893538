<template>
    <div class="col-6 col-md-4 col-lg-3 my-2">
        <div v-if="isMovable" class="toolbox w-100 d-flex justify-content-between p-2">
            <span  class="drag-icon" title="Reposition file">
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20"
                     width="30px"
                     height="30px"
                     class="bg-white rounded-circle p-1 m-1"
                     fill="#000"
                >
                    <path d="M1 4h2v2H1V4zm4 0h14v2H5V4zM1 9h2v2H1V9zm4 0h14v2H5V9zm-4 5h2v2H1v-2zm4 0h14v2H5v-2z"/>
                </svg>
            </span>

            <span class="delete-icon"
                  title="Remove file"
                  @click="removeFile()"
            >
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20"
                     width="30px"
                     height="30px"
                     class="bg-white rounded-circle p-1 m-1"
                     fill="#000"
                >
                    <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/>
                </svg>
            </span>
        </div>
        <div v-else class="toolbox-delete pointer">
            <svg xmlns="http://www.w3.org/2000/svg"
                 title="Remove file"
                 @click="removeFile()"
                 viewBox="0 0 20 20"
                 width="30px"
                 height="30px"
                 class="bg-white rounded-circle p-1 m-1"
                 fill="#000"
            >
                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/>
            </svg>
        </div>
        <div class="item-block rounded draggable">
            <div v-if="canBeShowInPreviewMode">
                <img class="img-responsive" :src="getBlob()" draggable="false" alt="car"/>
            </div>
            <div v-else style="padding-top:10px">
                <div class="file-icon">
                    <svg version="1.1"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         x="0px"
                         y="0px"
                         viewBox="0 0 317.001 317.001"
                         style="enable-background:new 0 0 317.001 317.001;" xml:space="preserve"
                         width="48px"
                         height="48px"
                    >
                        <path d="M270.825,70.55L212.17,3.66C210.13,1.334,207.187,0,204.093,0H55.941C49.076,0,43.51,5.566,43.51,12.431V304.57
                            c0,6.866,5.566,12.431,12.431,12.431h205.118c6.866,0,12.432-5.566,12.432-12.432V77.633
                            C273.491,75.027,272.544,72.51,270.825,70.55z M55.941,305.073V12.432H199.94v63.601c0,3.431,2.78,6.216,6.216,6.216h54.903
                            l0.006,222.824H55.941z"/>
                    </svg>

                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "ItemBlock",

    data: () => ({
        isMovable: false,
    }),
    props: {
        fileInfo: {
            type: File,
            required: true
        }
    },
    methods: {
        removeFile() {
            this.$emit('delete',  this.fileInfo);
        },

        getBlob() {
            return URL.createObjectURL(this.fileInfo)
        }
    },
    computed: {
        canBeShowInPreviewMode() {
            return true
        },
        fileName() {
            return this.fileInfo.name
        },
    }
}
</script>

<style scoped>
.item-block {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url('/images/transparent_logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0,0,0,.20);
}

.toolbox{
    margin-bottom: -50px;
    position: relative;
}

.toolbox-delete{
    margin-bottom: -40px;
    position: relative;
    width: 0%;
}

.img-responsive{
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.draggable { /* fallback: Chrome 1-21, Firefox 1.5-26, Safari 4+, IE, Edge 12-14, Android 2.1-4.4.4 */
    cursor: grab; /* W3C standards syntax, all modern browser */
}

.draggable:active {
    cursor: grabbing;
}
</style>
