export default [
    'auction.type',
    'auction.coming_soon',
    'auction.ended',
    'auction.buy_now',
    'auction.search_placeholder',
    'auction.filters.invoice',
    'auction.filters.invoice_true',
    'auction.filters.invoice_false',
    'auction.filters.make',
    'auction.filters.all',
    'auction.filters.model',
    'auction.filters.mileage',
    'auction.filters.from',
    'auction.filters.to',
    'auction.filters.transmission',
    'auction.filters.manual',
    'auction.filters.automatic',
    'auction.filters.seller_type',
    'auction.filters.engine_type',
    'auction.filters.engine_capacity',
    'auction.filters.engine_cylinders',
    'auction.filters.driver_side',
    'auction.filters.drivetrain',
    'auction.filters.exterior_color',
    'auction.filters.crypto_friendly',
    'auction.filters.yes',
    'auction.filters.no',
    'auction.filters.damage_type',
    'auction.filters.bid',
    'auction.filters.bidFrom',
    'auction.filters.bidTo',
    'auction.filters.yearFrom',
    'auction.filters.yearTo',
    'auction.filters.yearOfProduction',
    'auction.filters.minimal_price',
    'auction.filters.country_type',
    'auction.filters.sort_by',
    'auction.filters.ending_soon',
    'auction.filters.latest',
    'auction.filters.reset_filters',
    'auction.filters.close_filters',
    'auction.filters.sport',
    'auction.filters.premium',
    'auction.filters.popular',
    'auction.filters.save_search',
    'auction.filters.sign_in_to_save_search',
    'auction.filters.save_search_results',
    'auction.filters.search_saved',
    'auction.filters.lhd',
    'auction.filters.rhd',
    'auction.filters.private',
    'auction.filters.dealer',
    'auction.filters.insurance',
    'auction.filters.rental',
    'auction.filters.petrol',
    'auction.filters.petrol_lpg',
    'auction.filters.diesel',
    'auction.filters.hybrid',
    'auction.filters.electric',
    'auction.filters.rwd',
    'auction.filters.fwd',
    'auction.filters.awd',
    'auction.filters.frontend',
    'auction.filters.rearend',
    'auction.filters.side',
    'auction.filters.roof',
    'auction.filters.undercarriage',
    'auction.filters.interior',
    'auction.filters.outside',
    'auction.filters.burn',
    'auction.filters.mechanical',
    'auction.filters.vandalism',
    'auction.filters.waterfloot',
    'auction.filters.thieft_recovery',
    'auction.filters.sedan',
    'auction.filters.estate',
    'auction.filters.compact',
    'auction.filters.coupe',
    'auction.filters.convertible',
    'auction.filters.minivan',
    'auction.filters.suv',

    'auction.voivodeships.dolnoslaskie',
    'auction.voivodeships.kujawskopomorskie',
    'auction.voivodeships.lodzkie',
    'auction.voivodeships.lubelskie',
    'auction.voivodeships.lubuskie',
    'auction.voivodeships.malopolskie',
    'auction.voivodeships.mazowieckie',
    'auction.voivodeships.opolskie',
    'auction.voivodeships.podkarpackie',
    'auction.voivodeships.podlaskie',
    'auction.voivodeships.pomorskie',
    'auction.voivodeships.slaskie',
    'auction.voivodeships.swietokrzyskie',
    'auction.voivodeships.warminskomazurskie',
    'auction.voivodeships.wielkopolskie',
    'auction.voivodeships.zachodniopomorskie',
    

    'auction.no_cars',
    'account.sign_in',
    'auction.hot_deal',
    'auction.hot_deal_info',
    'auction.no_reserve_price',
    'auction.fresh',
    'auction.fresh_info',
    'auction.bids',
    'auction.views',
    'auction.accept_cryptocurrency',
    'auction.run_and_drive',
    'auction.car_stats',
    'auction.added_to_favorites',
    'auction.removed_to_favorites',
    'auction.no_results',
    'auction.new_comments',
    'auction.you_highest_bidder',
    'auction.notify',
    'auction.delete',
    'auction.delete_confirm_text',
    'auction.yes',
    'auction.no',
    'auction.max_bid_set_for',
    'auction.place_bid',
    'auction.coming_auction',
    'auction.sold',
    'auction.no_bids_yet',
    'auction.similiar_model',
    'auction.notify_me',
    'auction.how_buying',
    'auction.add_to_favorites',
    'auction.highest_bid',
    'auction.auction_ends',
    'auction.time_left',
    'auction.current_bid',
    'auction.place_max_bid',
    'auction.place_my_bid',
    'auction.insert_card_number',
    'auction.insert_expiration_date',
    'auction.update_payment_method',
    'auction.bids_and_questions',
    'auction.sign_in_to_ask',
    'auction.questions_and_anserws',
    'auction.seller',
    'auction.load_more',
    'auction.reply_to_comment',
    'auction.report_comment',
    'auction.report_title',
    'auction.reason',
    'auction.abusive_content',
    'auction.spam_or_wrong_content',
    'auction.false_information_or_scam',
    'auction.your_reason',
    'auction.submit',
    'auction.reported_comment',
    'auction.bid',
    'auction.search_bar',
    'auction.ask_seller',
    'auction.reply_to_question',
    'auction.reply',
    'auction.question_added',
    'auction.added_reply',
    'auction.no_comments_yet',
    'auction.no_questions_yet',
    'auction.max_bid_info',
    'auction.run',
    'auction.search.show',
    'auction.search.auction',
    'auction.search.auctions',
    'auction.filters.search_deleted',
    'auction.days',
    'auction.toast.comment_delete_successful',
    'auction.toast.box_empty',
    'auction.toast.bid_to_long',
    'auction.added_reply_to_comment',
    'auction.bid_success',
    'auction.comment',
    'auction.your_bid',
    'auction.all_auctions',
    'auction.click_here_to_upload',
    'auction.gallery_limit',
    'auction.image_to_big',
    'auction.format_notsupported',
    'auction.image_duplicate',
    'conversation.conversations',
    'auction.body_style',
    'conversation.after_winning',
    'conversation.type_sth',
    'conversation.send',
    'conversation.new_messages',

    'user_profile.save',
    'user_profile.nick',
    'user_profile.phone_number',
    'user_profile.name',
    'user_profile.surname',
    'user_profile.car_preferences',
    'user_profile.localisation',
    'user_profile.owned_cars',
    'user_profile.hobbies',
    'user_profile.cancel',
    'user_profile.edit',

    'affilation.promote_and_earn',
    'affilation.add_payment_method',
    'affilation.sign_in_to_earn',
    'affilation.affiliate_link_copied',
    'affilation.paid_affiliations',
    'affilation.ongoing_affiliations',
    'affilation.not_accepted',
    'affilation.your_earned_money',

    'draft_form.add_photos',
    'draft_form.photos_type_error',
    'draft_form.photos_size_error',
    'draft_form.over_images',
    'draft_form.format_not_supported',

    'colors.white',
    'colors.black',
    'colors.grey',
    'colors.silver',
    'colors.graphite',
    'colors.blue',
    'colors.green',
    'colors.red',
    'colors.yellow',
    'colors.orange',
    'colors.brown',
    'colors.pink',
    'colors.purple',
    'colors.custom',

    'favorites.favourite',
    'favorites.search',
    'billing.payment_change_successful',
    'billing.general_error',

    'profile.avatar_changed',
    'profile.password_changed',
    'profile.email_changed',
    'profile.avatar_changed',
    'profile.incorrect_password',
    'profile.error',
    'profile.profile_updated',
    'admin.accept_question',

    'favorites.filters.AUTOMATIC',
        'favorites.filters.MANUAL',
        'favorites.filters.PRIVATE',
        'favorites.filters.DEALER',
        'favorites.filters.INSURANCE',
        'favorites.filters.RENTAL',
        'favorites.filters.PETROL',
        'favorites.filters.DIESEL',
        'favorites.filters.HYBRID',
        'favorites.filters.ELECTRIC',
        'favorites.filters.PETROL_LPG',
        'favorites.filters.1-2',
        'favorites.filters.2-4',
        'favorites.filters.5-6',
        'favorites.filters.7-8',
        'favorites.filters.9-10',
        'favorites.filters.11-12',
        'favorites.filters.13-14',
        'favorites.filters.15-16',
        'favorites.filters.RHD',
        'favorites.filters.LHD',
        'favorites.filters.RWD',
        'favorites.filters.FWD',
        'favorites.filters.AWD',
        'favorites.filters.WHITE',
        'favorites.filters.BLACK',
        'favorites.filters.GREY',
        'favorites.filters.SILVER',
        'favorites.filters.GRAPHITE',
        'favorites.filters.BLUE',
        'favorites.filters.GREEN',
        'favorites.filters.RED',
        'favorites.filters.YELLOW',
        'favorites.filters.ORANGE',
        'favorites.filters.BROWN',
        'favorites.filters.PINK',
        'favorites.filters.PURPLE',
        'favorites.filters.CUSTOM',
        'favorites.filters.true',
        'favorites.filters.false',
        'favorites.filters.FRONTEND',
        'favorites.filters.REAREND',
        'favorites.filters.SIDE',
        'favorites.filters.ROOF',
        'favorites.filters.UNDERCARRIAGE',
        'favorites.filters.INTERIOR',
        'favorites.filters.BURN',
        'favorites.filters.MECHANICAL',
        'favorites.filters.VANDALISM',
        'favorites.filters.WATERFLOOT',
        'favorites.filters.0-10',
        'favorites.filters.10-25',
        'favorites.filters.25-50',
        'favorites.filters.50-100',
        'favorites.filters.100-250',
        'favorites.filters.250-500',
        'favorites.filters.THIEFT_RECOVERY',
];
