<template>
    <div v-if="message.is_users" class="w-100 d-flex justify-content-end py-1">
        <div class="bg-orange text-white d-flex flex-column rounded p-2 col-8">
            <span class="message-content">{{ message.message }}</span>
            <small class="text-right">{{ time }}</small>
        </div>
    </div>
    <div v-else class="py-1">
        <div class="bg-white border d-flex flex-column rounded p-2 col-8">
        <span class="message-content">{{ message.message }}</span>
        <small class="text-right">{{ time }}</small>
    </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            required: true
        },
    },
    computed: {
        time: function() {
            return new Date(this.message.when).toLocaleString('en-US');
        },
    }
}

</script>
