<template>

    <div class="container  px-0 px-md-3 py-5 pb-5 pt-lg-5  ">
        <div class="bg-gray border p-4">
            <div class="d-none d-lg-flex align-items-center pb-3">
                <h3 class="font-weight-bolder pl-3">{{ __('conversation.conversations') }}</h3>
                <button :title="__('conversation.after_winning')" class="btn p-0 ml-2 button-fw" data-placement="bottom"
                        data-toggle="tooltip">
                    <i class="far fa-question-circle mb-2 text-dark"></i>
                </button>
            </div>

            <div class="d-flex d-lg-none justify-content-end border-bottom align-items-center p-3">
                <i class="far fa-comment fa-lg pr-1"></i>
                <i class="fas fa-arrow-right"></i>
            </div>

            <div class="d-flex d-lg-none justify-content-start border-bottom align-items-center p-3"
                 v-on:click="conversation = null">
                <i class="fas fa-arrow-left"></i>
                <i class="far fa-comment fa-lg px-1"></i>
                <h6 class="p-0 m-0 font-weight-bolder">{{ __('conversation.conversations') }}</h6>
                <button :title="__('conversation.after_winning')" class="btn p-0 ml-2 button-fw" data-placement="bottom"
                        data-toggle="tooltip">
                    <i class="far fa-question-circle fa-sm text-dark"></i>
                </button>
            </div>

            <div v-if="conversations.length" class="d-flex border bg-gray">
                <div v-if="isMobile? !conversation: true"
                     id="v-pills-tab" v-dragscroll="true"
                     aria-orientation="vertical" class="nav d-block col-12 col-lg-4 p-0 nav-pills conversation-list" role="tablist">
                    <conversation
                        v-for="item in conversations"
                        :key="item.id"
                        :active="item.id === conversation.id"
                        :conversation="item"
                        @conversation-selected="changeConversation"
                    />
                </div>
                <div v-if="conversation" class="tab-content w-100">
                    <div class="tab-pane fade show active">
                        <div class="rounded">
                            <div class="conversation-header d-flex bg-gray p-2">
                                <div>
                                    <a :href="conversation.auction.route">
                                        <img :src="conversation.auction.image_url" alt="photo" class="rounded img-cover"
                                             height="75px"
                                             width="150px">
                                    </a>
                                </div>
                                <div class="d-flex flex-column justify-content-between w-100 pl-3">
                                    <h4 class="p-0 m-0 font-weight-bolder text-break text-uppercase">
                                        {{ conversation.auction.name }}</h4>
                                </div>
                            </div>
                            <messages :channel="conversation.channel" :conversation="conversation"
                                      :message-route="conversation.route" :messages-route="conversation.messages_route"
                                      @message-added="moveToTop"/>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex ">
                <p class="px-3 text-center text-md-left w-100">{{ __('auction.no_results') }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import Messages from './Messages.vue';
import Conversation from './Conversation.vue';

export default {
    components: {
        Messages,
        Conversation
    },
    props: {
        conversationsRoute: {
            required: true
        },
        channel: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            conversations: [],
            conversation: null
        };
    },
    methods: {
        getConversations() {
            axios.get(this.conversationsRoute).then(res => {
                this.conversations = res.data.conversations;
                if (this.conversations.length > 0) {
                    this.conversation = this.conversations[0];
                }
            });
        },
        listenNewMessagesInConversations() {
            Echo.private(this.channel)
                .listen('MessageAddedConversation', (data) => {
                    this.moveToTopNew(data.data);
                    if (this.conversation && data.data.id == this.conversation.id)
                        data.data.new_messages = false;
                })
        },
        changeConversation(conversation) {
            this.conversation = conversation;
        },
        moveToTop(conversation) {
            this.conversations = this.conversations.filter(function (ele) {
                return ele.id != conversation.id;
            });
            this.conversations.unshift(conversation);
        },
        moveToTopNew(conversation) {
            this.moveToTop(conversation)
            conversation.new_messages = true;
        }
    },
    mounted() {
        this.getConversations();
        this.listenNewMessagesInConversations();
    },
    computed: {
        isMobile: function () {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
    },
};

</script>
