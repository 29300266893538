<template>
    <div class="w-full">
        <VueUploader v-model="oldFiles"
                     :input-name="inputName"
                     :images="images"
                     :max-photos="maxPhotos"
                     :disk-config="diskConfig"
        />
    </div>
</template>

<script>
import VueUploader from "./VueUploader";

export default {
    components: {VueUploader},
    props: {
        inputName: {
            required: true,
            type: String
        },
        images: {
            required: false
        },
        maxPhotos: {
            required: true,
            type: Number
        },
        diskConfig: {
            required: true
        }
    },
    data: () => ({
        oldFiles: [],
    }),
}
</script>

<style scoped>
</style>
