<template>
  <div class="carousel">
    <slot></slot>
    <div class="radio-buttons">
      <label v-for="(slide, i) in slides" :key="slide+'-'+i" :class="{ active: index === i }" @click="goToSlide(i)">
        <input type="radio" :value="i" v-model="index" />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 0,
      time: 7000,
      slides: [],
      slideDirection: '',
      autoRotateInterval: null,
    };
  },

  computed: {
    slidesLength() {
      return this.slides.length;
    }
  },
  mounted() {
    this.slides = this.$children;
    this.slides.map((slide, index) => {
      slide.index = index;
    });
    this.startAutoRotate();
  },
  methods: {
    next() {
      this.index++;
      if (this.index >= this.slidesLength) {
        this.index = 0;
      }
      this.slideDirection = 'slide-right';
    },
    prev() {
      this.index--;
      if (this.index < 0) {
        this.index = this.slidesLength;
      }
      this.slideDirection = 'slide-left';
    },
    goToSlide(index) {
      if (this.index < index) {
        this.slideDirection = 'slide-right';
      } else if (this.index > index) {
        this.slideDirection = 'slide-left';
      }
      this.index = index;
    },
    startAutoRotate() {
      this.autoRotateInterval = setInterval(() => {
        this.next();
      }, this.time);
    },
    stopAutoRotate() {
      clearInterval(this.autoRotateInterval);
    },
  }
};
</script>
<style scoped>
.carousel {
  position: relative;
  width: 300px; 
  height: 400px; 
  border-radius: 30px; 
  border: 1px;
}

.radio-buttons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  justify-content: center;
}

.radio-buttons label {
  margin-right: 10px;
}

.radio-buttons input[type="radio"] {
  display: none;
}

.radio-buttons label {
  cursor: pointer;
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 4px;
}

.radio-buttons label.active {
  background-color: #0c44c7;
  color: #fff;
}
</style>
