<template>
    <div class="dropdown d-flex align-items-center">
        <a style="font-weight: 700;" id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true" class="p-0 webkit" data-toggle="dropdown" type="button">
            <span>{{ __("auction.type") }}</span>
            <i class="fas fa-chevron-down fa-sm pl-1 rotate"></i>
        </a>
        <div aria-labelledby="dropdownMenuButton" class="dropdown-menu mt-1 py-0">
            <a id="pills-home-tab"
               :class="params.status === 'published' ? 'active' : ''"
               class="nav-link tabs dropdown-item py-2"
               aria-controls="pills-home"
               data-toggle="pill"
               href=""
               role="tab"
               v-on:click="getAuctionsType('status','published')"
            >
                {{ __("auction.coming_soon") }}
            </a>
            <a id="pills-home-tab"
               :class="params.hot_deal === 'true' ? 'active' : ''"
               class="nav-link tabs dropdown-item py-2"
               aria-controls="pills-home"
               data-toggle="pill"
               href=""
               role="tab"
               v-on:click="getAuctionsType('hot_deal', true)"
            >
                {{ __("auction.buy_now") }}
            </a>

            <a id="pills-home-tab"
               :class="allAuction()? 'active' : ''"
               class="nav-link tabs dropdown-item py-2"
               aria-controls="pills-home"
               data-toggle="pill"
               href=""
               role="tab"
               v-on:click="resetFilters()"
            >
                {{ __("auction.all_auctions") }}
            </a>
            <!-- Narazie Typ aukcji "sprzedane" wyłączone, może wrócić wkrótce   -->
            <!-- <a
              class="nav-link tabs dropdown-item py-2"
              :class="params.status == 'sold' ? 'active' : ''"
              href=""
              role="tab"
              aria-controls="pills-profile"
              data-toggle="pill"
              id="pills-profile-tab"
              v-on:click="getAuctionsType('sold')"
              >{{ __('auction.sold') }}</a
            > -->
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        getAuctionsType(type, value) {
            this.$store.dispatch("changeParams", {
                paramName: "status",
                param: type,
            });
            window.location = '?' + type + '=' + value
        },
        initialState() {
            return {
                make: this.$store.state.params.make,
                model: this.$store.state.params.model,
                mileage: this.$store.state.params.mileage,
                transmission: this.$store.state.params.transmission,
                seller_type: this.$store.state.params.seller_type,
                engine_type: this.$store.state.params.engine_type,
                engine_capacity: this.$store.state.params.engine_capacity,
                engine_cylinders: this.$store.state.params.drive,
                control: this.$store.state.params.control,
                drive: this.$store.state.params.drive,
                colour: this.$store.state.params.colour,
                accept_crypto: this.$store.state.params.accept_crypto,
                drive_type: this.$store.state.params.drive_type,
                damage_type: this.$store.state.params.damage_type,
                sort_by: this.$store.state.params.sort_by,
                bid_from: this.$store.state.params.bid_from,
                bid_to: this.$store.state.params.bid_to,
                year_from: this.$store.state.params.year_from,
                year_to: this.$store.state.params.year_to,
                minimal_price: this.$store.state.params.minimal_price,
                country_type: this.$store.state.params.country_type,
                invoice_type: this.$store.state.params.invoice_type,
                models: null,
            }
        },
        resetFilters() {
            this.$store.commit('CLEAR_FILTERS');
            Object.assign(this.$data, this.initialState());
            let filters = ['bid_to', 'bid_from', 'year_to', 'year_from']
            filters.forEach(element => document.getElementById(element).value = '')
        },
        allAuction() {
            return (
                this.$store.state.params.make == null &&
                this.$store.state.params.model == null &&
                this.$store.state.params.mileage == null &&
                this.$store.state.params.transmission == null &&
                this.$store.state.params.seller_type == null &&
                this.$store.state.params.engine_type == null &&
                this.$store.state.params.engine_capacity == null &&
                this.$store.state.params.engine_cylinders == null &&
                this.$store.state.params.control == null &&
                this.$store.state.params.drive == null &&
                this.$store.state.params.colour == null &&
                this.$store.state.params.accept_crypto == null &&
                this.$store.state.params.damage_type == null &&
                this.$store.state.params.type == null &&
                this.$store.state.params.status == null &&
                this.$store.state.params.bid_from == null &&
                this.$store.state.params.bid_to == null &&
                this.$store.state.params.year_from == null &&
                this.$store.state.params.year_to == null &&
                this.$store.state.params.minimal_price == null &&
                this.$store.state.params.country_type == null &&
                this.$store.state.params.invoice_type == null &&
                this.$store.state.params.hot_deal == null
            );
        }
    },
    computed: {
        params: function () {
            return this.$store.state.params;
        },
    },
};
</script>
