<template>
<div class="w-100 p-0 m-0 align-items-center">
    <form class="position-relative d-flex justify-content-between rounded w-100 text-center border"
        method="get" id="searchForm"
        @submit.prevent="search">
        <div class="w-100 searchWrapper">
            <input :id="idProps" name="search_query"
                type="search" class="form-control search-input text-left border-0" maxlength="51"
                :placeholder="__('auction.search_placeholder')" aria-label="search for cars"
                aria-describedby="basic-addon2"
                v-model="searched">
        </div>
        <div class="input-group-append">
            <button class="btn border-0 py-0 button-fw pr-0" type="submit">
                <i class="fa fa-search text-muted"></i>
            </button>
        </div>
    </form>
</div>
</template>

<script>
export default {
    props: {
        searchedProps: {
            required: false
        },
        searchRouteProps: {
            required: true
        },
        idProps: {
            required: false
        }
    },
    created: function () {
        this.searched = this.searchedProps;
        let currentLocation = location.protocol + '//' + location.host + location.pathname;
        currentLocation = currentLocation.charAt(currentLocation.length-1) == '/'? currentLocation.substring(0, currentLocation.length - 1): currentLocation;
        if(window.location.origin != currentLocation)
            this.isHomePage = false;
        let keys = Object.keys(this.params);
        let currentUrl = new URL(window.location.href);
        if(this.isHomePage)
            for(let i = 0; i < keys.length; i++)
                if(currentUrl.searchParams.get(keys[i]))
                    this.$store.dispatch('changeParams', {paramName:keys[i] ,param: currentUrl.searchParams.get(keys[i])});
        this.$store.dispatch('changePage', new URL(window.location.href).searchParams.get('page'));
        this.$store.dispatch('search', this.searched);

    },
    data() {
        return {
            searchRoute: null,
            searched: "",
            isHomePage: true,
        };
    },
    methods: {
        search() {
            if(this.isHomePage)
                return;
            this.$store.dispatch('search', this.searched);
            this.changePage();
            this.toTop();
        },
        changePage() {
            let newUrl = new URL(window.location.origin);
            if(this.searched)
                newUrl.searchParams.set('search_query', this.searched);
            let values = Object.values(this.params);
            let keys = Object.keys(this.params);
            for(let i = 0; i < keys.length; i++)
                if(values[i] != null)
                    newUrl.searchParams.set(keys[i], values[i]);
            if(this.page)
                newUrl.searchParams.set('page', this.page);
            if(newUrl.href != window.location.href) {
                window.history.pushState(null, null, newUrl);
            }
            if(!this.isHomePage)
                window.location.reload();
        },
        toTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },
    },
    computed: {
        Url: function() {
            return new URL(window.location.origin);
        },
        params: function() {
            return this.$store.state.params;
        },
        page: function() {
            return this.$store.state.page;
        },
    },
    watch: {
        params: {
            handler(value) {
                this.changePage();
            },
            deep: true
        },
        searched: {
            handler(value) {
                if(!this.isHomePage)
                    return;
                this.$store.dispatch('search', this.searched);
                this.changePage();
                this.toTop();
            },
        }
    },
};
</script>
