import { render, staticRenderFns } from "./BidToConfirm.vue?vue&type=template&id=55764aad&"
import script from "./BidToConfirm.vue?vue&type=script&lang=js&"
export * from "./BidToConfirm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports