<template>
    <!-- offer -->
    <div>
        <time-bar :auction-end-props="auction.end" />
        <!-- image -->

        <div class="listing-img rounded position-relative">
            <a :href="auction.route">
                <img :src="auction.image_url" alt="photo" class="border-0" style="object-fit: cover; object-position: center;" loading="lazy">
                <img class="inner-image" style="filter: opacity(50%); "  src="/images/logo_footer.svg">
            </a>

            <a v-if="auction.fresh" data-toggle="tooltip" data-placement="bottom" :title="__('auction.fresh_info')"
                class="fresh position-absolute px-2 mr-2 bg-green text-white border-0 font-weight-bolder text-uppercase">
                <span>{{ __('auction.fresh') }}</span> <i class="fas fa-fire-alt ml-2"></i>
            </a>

            <div class="position-absolute p-2 black-div">
                <favourite-and-share
                    :auction-fav-route="auction.fav_route"
                    :fav-props="auction.fav"
                    :share-route="auction.route"
                    :status="auction.car_status"
                >
                </favourite-and-share>
            </div>
        </div>

        <div class="d-flex justify-content-end w-100 pl-xl-2">
            <div class="amount-timer d-flex">
                <div class="time-div  postition-relative px-3 align-items-center d-flex" :class="auction.status === 'published' ? 'bg-black text-white' : 'bg-white'">
                    <i class="far fa-clock pr-1"></i>
                    <timer :auction-end-props="auction.end" :auction-status-props="auction.status" />
                </div>
                <div v-if="auction.status !== 'published'" class="bg-dark d-flex align-items-center px-3 font-weight-bolder bid">
                    <span class="text-white"><money :currentBidProps="auction.current_bid" :currency="auction.currency"></money></span>
                </div>
            </div>
        </div>

        <!-- title -->
        <div class="px-xl-0 d-flex justify-content-between">
            <div class="px-3 px-md-0 pb-lg-2">
                <h5 class="text-uppercase mb-2 name-clamp">
                    <a :href="auction.route" class="text-dark" data-toggle="tooltip" data-placement="bottom" :title='auction.name'>
                        <strong>{{ auction.name }}</strong>
                    </a>
                </h5>
            </div>
        </div>
        <!-- description -->
        <div class="px-3 px-md-0 pb-1 tooltip-space">
            <a v-if="auction.hot_deal" data-toggle="tooltip" data-placement="bottom" :title="__('auction.hot_deal_info')"
                class="mr-1 px-2 letter-0 bg-red text-white border-0 font-weight-bolder text-uppercase rounded cursor-pointer">
                <span>{{ __('auction.hot_deal') }} </span>
            </a>
            <a v-if="auction.accept_crypto" class="mr-1 pointer" data-toggle="tooltip" data-placement="bottom" :title="__('auction.accept_cryptocurrency')">
                <img :src="bitcoin" alt="bitcoin" width="25px" height="25px">
            </a>
            <a v-if="auction.car_status === 'R'" class="mr-1 pointer" data-toggle="tooltip" data-placement="bottom" :title="__('auction.run_and_drive')">
                <img :src="runAndDrive" alt="runAndDrive" width="25px" height="25px">
            </a>
            <a v-if="auction.car_status === 'S'" class="mr-1 pointer" data-toggle="tooltip" data-placement="bottom" :title="__('auction.run')">
                <img :src="startEngine" alt="startEngine" width="25px" height="25px">
            </a>
        </div>
        <div class="px-3 px-md-0 description mb-2 font-italic">
            <span class="text-secondary text-break" v-html="auction.bidparks_take.replace(/<\/?[^>]+(>|$)/g, '') "></span>
        </div>
        <!-- region -->
        <div class="px-3 px-md-0 d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <!-- <img class="auction-country-flag mr-2 border" :src="flagIcon"> -->
                <span class="text-secondary"> {{auction.city}}</span>
            </div>
            <div>
                <span class="font-weight-bolder">{{auction.number_of_bids}} {{ __('auction.bids') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import truncate from '../filters/truncate';
    import Timer from './Timer.vue';
    import TimeBar from './TimeBar.vue';
    import Money from './Money.vue';
    import FavouriteOnPhoto from './FavouriteOnPhoto.vue';
    export default {
        components: {
            Timer,
            TimeBar,
            Money,
            FavouriteOnPhoto
        },
        props: {
            auction: {
                required: true
            },
        },
        filters: {
            truncate
        },
        data() {
            return {
                bitcoin: 'images/bitcoin.svg',
                runAndDrive: 'images/R.svg',
                startEngine: 'images/S.svg',
            };
        },
        computed: {
            flagIcon() {
                return `images/flags/${this.auction.country.flag}`
            }
        }
    };
</script>

<style scoped lang="scss">
.name-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

    &:hover {
        -webkit-line-clamp: 1;
    }
}

.inner-image {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width:5%;
}

.tooltip-space {
    min-height: 30px;
}

</style>
