<template>
  <div v-if="paramValue!=null" class="py-1 px-2 border rounded bg-darkgray">
    <h5 class="param-value font-weight-bolder m-0 text-uppercase text-lg">
      {{__('favorites.filters.' + paramValue). indexOf('.') == -1 ?  __('favorites.filters.' + paramValue) : paramValue}}
    </h5>
  </div>
</template>

<script>
export default {
  props: {
    paramValue: { required: false },
  },
};
</script>

<style lang="scss" scoped>
</style>
