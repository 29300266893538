<template>
    <tr class="d-flex flex-column flex-md-row justify-content-between car-div-color">
        <td class="d-flex flex-column  flex-md-row pb-0 py-md-3 border-0 align-items-center flex-grow-1">
            <!-- photo -->
            <div>
                <a :href="affiliation.route">
                    <img :src="affiliation.image_url" alt="photo" class="rounded img-cover" width="150px" height="100px">
                </a>
            </div>
            <!-- informations -->
            <div class="d-flex flex-column align-items-center align-items-md-start">
                <div class="px-3 pt-2 pt-md-0 d-flex flex-wrap align-items-center justify-content-center text-center">
                    <a class="text-dark" :href="affiliation.route">
                        <h3 class="font-weight-bolder text-uppercase mb-0">{{affiliation.name}}</h3>
                    </a>
                </div>
                <div class="px-3 d-flex flex-row flex-md-column align-items-start justify-content-center">
                    <span class="text-muted pr-0 pr-md-2">{{affiliation.country}}, {{affiliation.city}}</span>
                </div>
            </div>
        </td>
        <!-- time/price -->
        <td class="d-flex border-0 justify-content-center flex-shrink-0">
            <div class="d-flex flex-column align-items-center align-items-md-end justify-content-center">
                <div class="d-flex justify-content-end">
                    <i class="far fa-clock pr-2"></i>
                    <span class="font-weight-bolder">{{end}}</span>
                </div>
                <div class="d-flex justify-content-end  px-2 pl-2 pr-md-0 py-md-2">
                    <h5 class="text-orange font-weight-bolder mb-0"><money :currentBidProps="affiliation.money" :currency="affiliation.currency"></money></h5>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import Money from './Money.vue';
export default {
    components: { 
        Money 
    },
    props: {
        affiliation: {
            required: true
        },
    },
    computed: {
        end: function() {
            return new Date(this.affiliation.end).toLocaleString('en-US');
        },
    }
};

</script>
