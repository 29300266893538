<template>
    <div v-if="editable">
        <input v-bind="$attrs" class="border col-xl-6 rounded p-2" :value="value" @input="$emit('input', $event.target.value)">
    </div>
    <div v-else>
        {{ value }}
    </div>
</template>

<script>
    export default {
        inheritAttrs: false,
        props: {
            editable: {
                type: Boolean,
                default: false,
            },
            value: {
                type: [String, Number]
            }
        },
        computed: {
            valueText() {
                return this.$attrs.value 
            }
        },
    }
</script>