<template>
    <ul v-if="bids.length" class="bid-list pl-0" v-dragscroll="true">
        <bid
            v-for="bid in bids"
            :key="bid.id"
            :bid="bid"
            :language="locale"
        ></bid>
    </ul>
    <div v-else>
        {{ __('auction.no_bids_yet') }}.
    </div>
</template>

<script>
import Bid from './Bid.vue';
    export default {
  components: { Bid },
        props: {
            channel: {
                type: String,
                required: true
            },
            route: {
                required: true
            },
            language: {
                required: true
            }
        },
        data() {
            return {
                bids: {},
                locale: this.language
            };
        },
        methods: {
            getBids() {
                axios.get(this.route).then(res => {
                    this.bids = res.data.bids;
                });
            },
            listenNewBids() {
                Echo.channel(this.channel)
                    .listen('BidAdded', (data) => {
                        this.bids.unshift(data.data);
                    })
            },
        },
        mounted() {
            this.getBids();
            this.listenNewBids();
        },
    };

</script>
