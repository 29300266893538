<template>
    <tr v-if="status == 'ongoing'"
        class="d-flex flex-column flex-md-row p-md-3 my-2 my-md-0 justify-content-center justify-content-md-between car-div-color">
        <td
            class="d-flex flex-column p-0 pb-md-2 flex-md-row justify-content-start text-center border-0 flex-grow-1">
            <div>
                <a class="image-wrapper" :href="auction.route">
                <img :src="auction.image_url"
                    alt="photo" class="img-cover mw-100 mh-100" height="100px" width="150px">
                </a>
            </div>
            <div
                class="pl-md-3 pt-3 pt-md-0 py-0 d-flex flex-column align-items-center align-items-md-start">
                <h3 class="font-weight-bolder mb-0">
                    <a :href="auction.route" class="text-dark text-break">
                        {{ auction.name }}
                    </a>
                </h3>
                <span class="text-muted pb-2">{{ auction.country }},
                    {{ auction.city }}</span>
            </div>
        </td>
        <td
            class="d-flex align-items-center py-0 py-md-2 justify-content-center border-0">
            <div v-if="auction.highest_bidder">
                <h4 class="text-muted font-weight-bolder">{{ __('auction.you_highest_bidder') }}</h4>
            </div>
        </td>
        <td class="border-0 p-3">
            <div class="d-flex flex-md-column justify-content-center">
                <div class="d-flex justify-content-center align-items-center justify-content-lg-end">
                    <i class="far fa-clock pr-2"></i>
                    <span class="font-weight-bolder">
                        <timer
                            :auction-end-props="auction.end" :auction-status-props="auction.status"
                        ></timer>
                    </span>
                </div>
                <div
                    class="d-flex px-2 px-md-0 py-md-2 justify-content-center justify-content-lg-end">
                    <h5 class="text-orange font-weight-bolder mb-0">
                        <money :currentBidProps="auction.current_bid" :currency="auction.currency"></money>
                    </h5>
                </div>
                <div class="d-flex justify-content-center justify-content-lg-end">
                    <span class="font-weight-bolder">
                        {{auction.number_of_bids}} {{ __('auction.bids') }}
                    </span>
                </div>
            </div>
        </td>
    </tr>
    <tr v-else-if="status == 'ended'"
        class="d-flex flex-column flex-md-row pb-3 pb-md-0 my-2 my-md-0 justify-content-center justify-content-md-between car-div-color">
        <td class="d-flex flex-column flex-md-row border-0 flex-grow-1">
            <div class="d-flex justify-content-center">
                <a class="image-wrapper" :href="auction.route">
                <img :src="auction.image_url"
                    alt="photo" class="img-cover mw-100 mh-100" width="150px"
                    height="100px">
                </a>
            </div>
            <div
                class="px-3 d-flex flex-column align-items-center align-items-md-start">
                <h3 class="font-weight-bolder mb-0 pt-2 pt-md-0 text-uppercase">
                    <a :href="auction.route" class="text-dark text-break">
                        {{ auction.name }}
                    </a>
                </h3>
                <span class="text-muted pb-2">{{ auction.country }},
                    {{ auction.city }}</span>
            </div>
        </td>
    </tr>
    <tr v-else-if="status == 'bought'"
        class="d-flex flex-column flex-md-row pb-3 pb-md-0 my-2 my-md-0 justify-content-center justify-content-md-between car-div-color">
        <td
            class="d-flex flex-column pb-0 pt-2 pb-md-2 flex-md-row border-0 flex-grow-1">
            <div class="d-flex justify-content-center">
                <a class="image-wrapper" :href="auction.route">
                    <img :src="auction.image_url"
                        alt="photo" class="rounded img-cover"
                        width="150px" height="100px">
                </a>
            </div>
            <div
                class="px-3 d-flex flex-column align-items-center align-items-md-start">
                <h3
                    class="font-weight-bolder mb-0 pt-2 pt-md-0 text-uppercase">
                    <a :href="auction.route" class="text-dark text-break">
                        {{ auction.name }}
                    </a>
                </h3>
                <span
                    class="text-muted pb-2">{{ auction.country }},
                    {{ auction.city }}</span>
            </div>
        </td>
        <td
            class="d-flex p-3 flex-column justify-content-center align-items-center align-items-lg-end border-0">
            <div class="d-flex justify-content-center">
                <h5 class="font-weight-bolder py-0 mb-0">{{ __('auction.your_bid') }}:
                    <money :currentBidProps="auction.current_bid" :currency="auction.currency"/></h5>
            </div>
        </td>
    </tr>
</template>

<script>
import Timer from './Timer.vue';
import Money from './Money.vue';
export default {
    components: {
        Timer,
        Money
    },
    props: {
        auction: {
            required: true
        },
        status: {
            required: true
        }
    },
    data() {
        return {
            timeLeft: null,
            notify: null,
        };
    },
    methods: {
        numberWithSpaces(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            if(parts.length > 1)
                if(parts[1].length == 1)
                    parts[1]+='0';
            return parts.join(".");
        },
    },
    computed: {
        currentBid: function() {
            return this.numberWithSpaces(this.auction.current_bid);
        },
    }
};

</script>
